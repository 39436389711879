module.exports = {
  Settings: {
    appName: "DSCVRY",
    appVersion: "1.0",
    environment: "app",
    mainDomain: "app.dscvry.co",
    groupDomain: "app-dscvry-com",
    siteId: "",
    gqlServer: {
      url: "https://api.dscvry.co/",
      ws: "wss://api.dscvry.co"
      // url: 'http://localhost:4001'
    },
    mailerServer: {
      url: "https://mailer.dscvry.co",
    },
    flow: "modal",
    winston: {
      level: "log",
    },
    sentry: {
      dsn: "https://a1d3ec6f19d2465c82f4e0b59e5f032e@o327848.ingest.sentry.io/4504709457575936",
      logLevel: ["error"], // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
    },
    intercomAppId: "",
    appKey:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHAiOiJDaGV3Ym94IiwidXNlcklkIjoiNjIzMTAxYWNhZGJlMWQwMDA4YTM5OWFmIiwiaWF0IjoxNjUxMDQ4NTUyfQ.1SdS4XkT-HQp7jdx6O4PMJt2Gh9brSRtFblPsoBLVsY",
    primary: "#0000FF",
    logo: {
      dark: {},
      light: {
        mobile: "",
        desktop: "",
      },
    },
    nextbillion: {
      api_key: "",
      api_host: "",
    },
    aws: {
      bucket: "dscvry-staging-media",
      region: "us-east-1",
      accessKeyId: "AKIA5PSQCDODAMNW6ROA",
      secretAccessKey: "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",
    },
    googleMapKey: "AIzaSyBgDdvEYCqfsApDl5sQMlGqxfs-VJDhsmc",
    adminEmail: "info@zyoninc.com",
    usObj: {
      place_id: "ChIJCzYy5IS16lQRQrfeQ5K5Oxw",
      name: "United States",
    },
  },
};
