<template>
    <div class="flex flex-col w-full h-screen relative">
        <div class="flex flex-col w-full mt-6 mb-2 sm:px-4">
            <Header
                @onAdd="() => $emit('onAdd')"
                :title="'Contacts'"
                :count="pagination && pagination.totalRecords"
                :searchbox="{ placeholder: 'Search', icon: 'SearchIcon' }"
                :searchText="searchText"
                :onSearchTextChange="(value) => this.onSearchTextChange(value)"
            />
            <div class="flex justify-between items-center px-4">
                <Text
                    size="sm"
                    color="primary-600"
                    content="View Interview Schedule"
                    decoration="underline"
                    custom-class="cursor-pointer"
                />
                <div class="flex items-center space-x-3">
                    <Text
                        v-if="selectedItems.length > 0 && !isDeleting"
                        size="sm"
                        color="rose-600"
                        content="Archive Selected"
                        decoration="underline"
                        custom-class="cursor-pointer"
                        @click="() => $emit('onArchive', selectedItems, onDone)"
                    />
                    <svg
                        v-if="isDeleting"
                        class="w-5 h-5 animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            class="opacity-20"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            class="opacity-100"
                            fill="#574CCB"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                    <Text
                        size="sm"
                        color="primary-600"
                        content="Import Contacts"
                        decoration="underline"
                        custom-class="cursor-pointer"
                        @click="() => $emit('onImport')"
                    />
                    <Text
                        @click="() => (isOpenExport = true)"
                        size="sm"
                        color="primary-600"
                        content="Export as CSV"
                        decoration="underline"
                        custom-class="cursor-pointer"
                    />
                </div>
            </div>
        </div>
        <Loader v-if="isPageLoading" :isLoading="isPageLoading" />

        <div
            v-else
            class="flex flex-col w-full h-screen px-4 overflow-scroll sm:px-8 space-y-2"
        >
            <EmptyState
                v-if="contacts && contacts.length === 0"
                class="bg-white"
                isFull
                v-bind="emptyContact"
                :action="
                    () => {
                        $emit('onAdd');
                    }
                "
            />
            <Table
                :list="contacts"
                :headers="headers"
                @onClickItem="(item) => $emit('onClickItem', item)"
                isRow
                isClickable
                isMultipleSelection
                @onSelected="
                    (selected, callback) => {
                        selectedItems = selected;
                        onDone = callback;
                    }
                "
            />
            <CardFooterWithPageButtons
                v-bind="pagination"
                class="px-0 bg-opacity-0"
            />
        </div>
        <ExportModal
            :is-show="isOpenExport"
            @onClose="() => (isOpenExport = false)"
            :title="getTitle()"
            :description="`A .csv file will be downloaded to your device.`"
            yes-btn-text="Export"
            no-btn-text="Cancel"
            icon="UsersIcon"
            yesBtnVariant="primary"
            noBtnVariant="secondary"
            :on-no-click="() => (isOpenExport = false)"
            :on-yes-click="() => onExport()"
            :isLoading="isLoading"
        />
    </div>
</template>

<script>
import Header from "../../molecules/ListViewHeader/WithFilter/ListViewHeader.vue";
import Text from "../../atoms/Text/Text";
import Table from "../../organisms/Tables/WithSortAndFilter/WithSortAndFilter.vue";
import CardFooterWithPageButtons from "../../organisms/Paginations/CardFooterWithPageButtons/CardFooterWithPageButtons.vue";
import EmptyState from "../../organisms/EmptyState/Simple/Simple.vue";
import ExportModal from "../../organisms/Modals/SimpleAlert/SimpleAlert";
import Loader from "../../atoms/Loader/Loader.vue";

export default {
    components: {
        Header,
        Text,
        Table,
        CardFooterWithPageButtons,
        EmptyState,
        ExportModal,
        Loader,
    },
    props: {
        contacts: {
            type: Array,
            default: () => [],
        },
        pagination: {
            type: Object,
            default: () => {},
        },
        headers: {
            type: Array,
            default: () => [],
        },
        venture: {
            type: Object,
            default: () => {},
        },
        searchText: {
            type: String,
            default: "",
        },
        onSearchTextChange: {
            type: Function,
            default: () => {},
        },
        isPageLoading: {
            type: Boolean,
        },
        isDeleting: {
            type: Boolean,
        },
    },
    data() {
        return {
            emptyContact: {
                title: "Let's add the contacts",
                description: "Please add the contacts",
                btnText: "Add Contact",
                icon: "LightBulbIcon",
            },
            isOpenExport: false,
            isLoading: false,
            perPage: 10,
            currentPage: 1,
            selectedItems: [],
            onDone: null,
        };
    },
    methods: {
        getTitle() {
            return `Export <b>${this.contacts?.length} Contacts?</b>`;
        },
        async onExport() {
            if (this.isLoading) return false;
            if (this.contacts?.length === 0) return false;
            this.isLoading = true;
            const contacts = this.venture.contacts;
            const records = [];
            contacts?.map((c) => {
                records.push({
                    "First Name": c?.user?.firstName,
                    "Last Name": c?.user?.lastName,
                    Email: c?.user?.email,
                    Status: c?.status,
                    Relationship: c?.relationship,
                    "Target Customer": c?.targetCustomerUser?.name,
                    Facebook: c?.user?.socialHandles?.find(
                        (s) => s?.platform === "FACEBOOK"
                    )?.handle,
                    Twitter: c?.user?.socialHandles?.find(
                        (s) => s?.platform === "TWITTER"
                    )?.handle,
                    Instagram: c?.user?.socialHandles?.find(
                        (s) => s?.platform === "INSTAGRAM"
                    )?.handle,
                    Reddit: c?.user?.socialHandles?.find(
                        (s) => s?.platform === "REDDIT"
                    )?.handle,
                    TikTok: c?.user?.socialHandles?.find(
                        (s) => s?.platform === "TIKTOK"
                    )?.handle,
                    Reddit: c?.user?.socialHandles?.find(
                        (s) => s?.platform === "REDDIT"
                    )?.handle,
                    LinkedIn: c?.user?.socialHandles?.find(
                        (s) => s?.platform === "LINKEDIN"
                    )?.handle,
                    Other: "",
                    "Date Added": c?.createdAt,
                });
            });

            const csvRows = [];
            const headers = Object.keys(records[0]);
            csvRows.push(headers.join(","));

            records?.map((r) => {
                const values = Object.values(r).join(",");
                csvRows.push(values);
            });

            const blob = new Blob([csvRows?.join("\n")], { type: "text/csv" });

            // Creating an object for downloading url
            const url = window.URL.createObjectURL(blob);

            // Creating an anchor(a) tag of HTML
            const a = document.createElement("a");

            // Passing the blob downloading url
            a.setAttribute("href", url);

            // Setting the anchor tag attribute for downloading
            // and passing the download file name
            a.setAttribute("download", "contacts.csv");

            // Performing a download with click
            a.click();
            this.actions.alert.showSuccess({
                message: `${contacts?.length} contacts have been successfully exported.`,
                title: "Contacts exported!",
            });
            this.isLoading = false;
            this.isOpenExport = false;
        },
    },
};
</script>

<style scoped></style>
