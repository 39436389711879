<template>
	<div class="flex flex-col pb-0 sm:pb-4 lg:divide-y divide-white lg:divide-gray-300">
		<div v-for="item in items" :key="item" class="h-full grid-cols-1 mt-0 sm:mt-8 lg:grid-cols-7 lg:px-8 gap-x-7"
			:class="item.isForm ? 'grid' : 'hidden sm:grid'">
			<Simple v-if="item.open" :title="item.header" :textHtml="item.textHtmlMobile"
				:onNoClick="() => { item.open = false; items = [...items]; }" :open="item.open" />
			<div class="h-full col-span-2 mt-0 sm:mt-4" :class="item.isForm && 'pt-7 sm:pt-0'">
				<div class="lg:flex-col">
					<div v-if="item.header" class="flex items-center lg:flex-row pl-4 lg:pl-0">
						<Text size="xl" weight="semibold" color="gray-900" :content="item.header"
							customClass="md:text-xl md:font-bold" />
						<Icon name="InformationCircleIcon" class="block mt-1 ml-2 lg:hidden"
							@click="() => { item.open = true; items = [...items]; }" color="gray-600" :isOutline="true" />
					</div>
					<Text v-if="item.header" size="sm" color="gray-500" weight="normal" custom-class="mt-2"
						:content="item.description" class="hidden lg:flex" />
					<!-- <Text size="sm" element="span" color="grayhelptext" :content="item.hideText"
						@click="() => { item.detailHidden = !item.detailHidden; items = [...items] }"
						class="hidden my-2  underline lg:flex cursor-pointer" /> -->

				</div>
				<!-- <div v-if="!item.detailHidden && item.textHtml" v-html="item.textHtml"
					class="flex-col hidden h-full mb-8 text-xs lg:flex">
				</div> -->
			</div>
			<hr class="block mt-2 sm:hidden" />
			<div v-if="item.isForm" class="col-span-5 mx-0  mt-4">
				<div class="shadow-lg h-fit rounded-md">
					<div class="px-4 py-8 space-y-4 bg-white border-t border-l border-r border-gray-300 rounded-t-md">
						<div class="flex flex-col w-full gap-4 my-1 lg:flex-row">
							<div v-if="item.name" class="flex w-full mx-0 sm:mx-1 lg:w-1/2">
								<Input v-model:value="item.name.value" name='inputEl' :label="item.name.label" type='text'
									helpText="Should be good balance between simple and description." />
							</div>
							<div v-if="item.types" class="flex w-full mx-0 sm:mx-1 lg:w-1/2">
								<Select class="w-full" :items="item.types.list" v-model:selectedItem="item.types.selected"
									:label="item.types.label"
									helpText="Individuals make this a B2C venture. Employees, businesses, schools, or governments make this a B2B or B2G venture." />
							</div>
						</div>
						<div v-if="get(item, 'types.selected.name') === 'INDIVIDUALS' || get(item, 'types.selected.name') === 'EMPLOYEES'"
							class="flex flex-col w-full gap-4 my-1 lg:flex-row">
							<div class="flex w-full mx-0 sm:mx-1  lg:w-1/2">
								<!-- <MultiSelectDropdown class="w-full max-w-5xl" :options="item.age.list" isObj isNotAdd
									v-model:selected="item.age.value" :label="item.age.label" :limit="1" /> -->
								<Select class="w-full" :items="item.age.list" v-model:selectedItem="item.age.value"
									:label="item.age.label"/>
							</div>
							<div class="flex w-full mx-0 sm:mx-1 lg:w-1/2">
								<!-- <MultiSelectDropdown class="w-full max-w-5xl" :options="item.gender.list" isObj isNotAdd
									v-model:selected="item.gender.value" :label="item.gender.label" :limit="1" /> -->
								<Select class="w-full" :items="item.gender.list" v-model:selectedItem="item.gender.value"
									:label="item.gender.label"/>
							</div>
						</div>
						<div v-if="get(item, 'types.selected.name') === 'INDIVIDUALS' || get(item, 'types.selected.name') === 'EMPLOYEES'"
							class="flex flex-col w-full gap-4 my-1 lg:flex-row">
							<div class="flex w-full mx-0 sm:mx-1  lg:w-1/2">
								<!-- <MultiSelectDropdown class="w-full max-w-5xl" :options="item.ethnicity.list" isObj isNotAdd
									v-model:selected="item.ethnicity.value" :label="item.ethnicity.label" :limit="1" /> -->
								<Select class="w-full" :items="item.ethnicity.list" v-model:selectedItem="item.ethnicity.value"
									:label="item.ethnicity.label"/>
							</div>
							<div class="flex w-full mx-0 sm:mx-1 lg:w-1/2">
								<!-- <MultiSelectDropdown class="w-full max-w-5xl" :options="item.income.list" isObj isNotAdd
									v-model:selected="item.income.value" :label="item.income.label" :limit="1"/> -->
								<Select class="w-full" :items="item.income.list" v-model:selectedItem="item.income.value"
									:label="item.income.label"/>
							</div>
						</div>
						<div v-if="get(item, 'types.selected.name') !== 'INDIVIDUALS' || get(item, 'types.selected.name') === 'EMPLOYEES'" class="flex flex-col w-full my-1">
							<div class="flex gap-4">
								<div v-if="item.estimatedAnnualIncome" class="flex w-full mx-0 sm:mx-1  lg:w-1/2">
									<MultiSelectDropdown class="w-full max-w-5xl" :options="item.estimatedAnnualIncome.list" isObj isNotAdd
										v-model:selected="item.estimatedAnnualIncome.value" :label="item.estimatedAnnualIncome.label" :limit="1" />
									<!-- <Select class="w-full" :items="item.estimatedAnnualIncome.list" v-model:selectedItem="item.estimatedAnnualIncome.value"
										:label="item.estimatedAnnualIncome.label"/> -->
								</div>
								<div v-if="item.employeeSize" class="flex w-full mx-0 sm:mx-1  lg:w-1/2">
									<MultiSelectDropdown class="w-full max-w-5xl" :options="item.employeeSize.list" isObj isNotAdd
										v-model:selected="item.employeeSize.value" :label="item.employeeSize.label" :limit="1" />
									<!-- <Select class="w-full" :items="item.employeeSize.list" v-model:selectedItem="item.employeeSize.value"
										:label="item.employeeSize.label"/> -->
								</div>
							</div>

							<Text size="xs"
								:content="'These tags are optional but can help find other DSCVRY users that might fit into this Target Customer segment'"
								weight="normal" color="gray-400" custom-class="-pt-3 italic"
								v-if="get(item, 'types.selected.name') !== 'INDIVIDUALS'" />
						</div>

						<div class="flex flex-col w-full gap-4 my-1 lg:flex-row">
							<div v-if="item.interestAndActivities" class="flex w-full mx-0 sm:mx-1  lg:w-1/2">
								<MultiSelectDropdown class="w-full max-w-5xl" :options="item.interestAndActivities.list" isObj
									v-model:selected="item.interestAndActivities.value" :label="item.interestAndActivities.label"
									description="Tags that specify what they might like (interests) or things they might do (activities)." />
							</div>
							<div v-if="item.markets" class="flex w-full mx-0 sm:mx-1 lg:w-1/2">
								<MultiSelectDropdown class="w-full max-w-6xl" isObj :options="get(state, 'google.placeResults', [])"
									v-model:selected="item.markets.value" :label="item.markets.label" isLocation
									@onSearch="searchGooglePlaces" isNotAdd  :limit="1"
									description="The geographical boundary for this group. Defaults to the United States." />
							</div>
						</div>
						<div class="flex flex-col w-full gap-4 my-1 lg:flex-row">
							<div v-if="item.estimatedMarketSize" class="flex w-full mx-0 sm:mx-1  lg:w-1/2">
								<Input v-model:value="item.estimatedMarketSize.value" name='inputEl'
									:label="item.estimatedMarketSize.label" type='number'
									helpText='Based on your research, and how many people are in this Target Customer Group. This is required to later calculate things like "adjusted market size.' />
							</div>
							<div v-if="item.researchSource" class="flex w-full mx-0 sm:mx-1  lg:w-1/2">
								<Input v-model:value="item.researchSource.value" name='inputEl' :label="item.researchSource.label"
									type='text'
									helpText="Add a url or other citation information when people ask how you calculated your Estimated and Adjusted market size." />
							</div>
						</div>
						<!-- <div v-if="item.researchSource" class="flex w-full mx-0 sm:mx-1">
							<Input v-model:value="item.researchSource.value" name='inputEl' :label="item.researchSource.label"
								type='text'
								helpText="Add a url or other citation information when people ask how you calculated your Estimated and Adjusted market size." />
						</div> -->
						<div class="flex flex-col w-full my-1 lg:flex-row">
							<div v-if="item.notes" class="flex flex-col w-full mx-0 sm:mx-1 ">
								<label class="text-sm font-semibold text-gray-700 label-sm">{{ item.notes.label }}</label>
								<textarea class="flex w-full h-16 p-2 border border-gray-300 rounded-md ring-1 ring-gray-300 text-sm"
									v-model="item.notes.value" :label="item.notes.value"></textarea>
								<Text size="xs" weight="" color="gray-400" custom-class="mt-1 italic"
									content="Should be good balance between simple and description." />
							</div>
						</div>
					</div>
					<div class="flex flex-row w-full bg-gray-100 border border-gray-300 rounded-b-none sm:rounded-b-md">
						<div v-if="item.notes" class="flex w-3/4">
						</div>
						<div v-if="item.notes" class="flex flex-row items-end justify-end w-1/4 my-4 mr-4 sm:mr-3 lg:mr-5">
							<Button content="Cancel" :variant="null" customClass="bg-white text-black underline px-2 bg-gray-100" />
							<Button @click="$emit('onSave')" content="Save" variant="primary" customClass="ml-4 px-4"
								:isLoading="isLoading" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue'
import Button from '../../atoms/Button/Button.vue'
import Input from '../../molecules/Inputs/Component/Component.vue'
import Select from '../../molecules/Selects/Component/Component.vue'
import Icon from '../../atoms/Icons/Icons.vue'
import Text from '../../atoms/Text/Text.vue'
import Simple from '../Modals/Simple/Simple.vue'
import MultiSelectDropdown from '../../molecules/Dropdown/MultiSelectDropdown/MultiSelectDropdown.vue'
import { get } from 'lodash'
/**
 * - Use it to show a FormFieldGroup
 */
export default {
	components: {
		Button,
		Select,
		Input,
		Text,
		Icon,
		Simple,
		MultiSelectDropdown
	},
	props: {
		/**
		 * Use it for FormFieldGroup items
		 */
		items: {
			type: Array,
			default: () => []
		},
		isLoading: {
			type: Boolean,
			default: true
		}

	},
	data() {
		return {
			get
		}
	},
	methods: {
		async searchGooglePlaces(val) {
			try {
				if (val) {
					await this.actions.google.searchGooglePlaces({
						keyword: val,
						types: ["political"]
					});
				}
			} catch (e) {
				console.log(e);
			}
		},
	}
}
</script>
