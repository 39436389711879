<template>
    <div class="flex flex-col w-full h-screen">
        <div class="flex flex-col w-full mt-6 mb-2 sm:px-4">
            <Header
                @onAdd="() => toggleSlideover(true)"
                :title="'People'"
                :count="people.length"
                :btn-label="'Invite'"
                :searchbox="{
                    placeholder: 'Find a person',
                    icon: 'SearchIcon',
                }"
                @onSort="
                    (sort) => {
                        $emit('onSort', sort);
                        recordActivePage = 1;
                    }
                "
                @onFilter="
                    (filter) => {
                        $emit('onFilter', filter);
                        recordActivePage = 1;
                    }
                "
                :searchText="searchText"
                :onSearchTextChange="(value) => this.onSearchTextChange(value)"
            />
            <div class="flex justify-end items-center px-4 -mt-6">
                <!--				<Text size="sm" color="primary-600" content="View Interview Schedule" decoration="underline" custom-class="cursor-pointer"/>-->
                <!--				<div class="flex items-center space-x-3">-->
                <!--					<Text size="sm" color="primary-600" content="Import Contacts" decoration="underline" custom-class="cursor-pointer"/>-->
                <Text
                    size="sm"
                    color="primary-600"
                    content="Export as CSV"
                    decoration="underline"
                    custom-class="cursor-pointer"
                    @click="() => $emit('onExport')"
                />
                <!--				</div>-->
            </div>
        </div>
        <Loader v-if="isPageLoading" :isLoading="isPageLoading" />
        <div v-else class="flex flex-col w-full h-screen px-4 sm:px-8 space-y-2">
            <template v-if="!isLoading && (!people || people.length === 0)">
                <EmptyState
                    class="bg-white"
                    isFull
                    :title="emptyViewBinding.title"
                    :description="emptyViewBinding.description"
                />
                <slot name="add"> </slot>
            </template>
            <template v-else>
                <Table
                    :list="getItems(people)"
                    @onClickItem="(item) => $emit('onClickItem', item)"
                    isClickable
                    :sortFields="['Name', 'Email', 'Team']"
                    @onSort="(item) => (sort = item)"
                />
                <CardFooterWithPageButtons
                    class="px-0 bg-opacity-0"
                    :onChangePerPage="(count) => perPageChanged(count)"
                    :totalRecords="people?.length"
                    :perPage="recordPerPage"
                    :currentPage="recordActivePage"
                    :onChangePage="(page) => pageChanged(page)"
                />
            </template>
        </div>
    </div>
</template>

<script>
import Header from "../../molecules/ListViewHeader/WithFilter/ListViewHeader.vue";
import Text from "../../atoms/Text/Text";
import Table from "../../organisms/Tables/Simple/Simple.vue";
import CardFooterWithPageButtons from "../../organisms/Paginations/CardFooterWithPageButtons/CardFooterWithPageButtons.vue";
import EmptyState from "../../organisms/EmptyState/Simple/Simple";
import Loader from "../../atoms/Loader/Loader.vue";

export default {
    components: {
        Header,
        Text,
        Table,
        CardFooterWithPageButtons,
        EmptyState,
        Loader,
    },
    props: {
        people: {
            type: Array,
            default: () => [],
        },
        emptyViewBinding: {
            type: Object,
            default: () => {},
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        searchText: {
            type: String,
            default: "",
        },
        onSearchTextChange: {
            type: Function,
            default: () => {},
        },
        toggleSlideover: {
            type: Function,
            default: () => {},
        },
        isPageLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            sort: {
                items: [
                    { id: 2, name: "First Name" },
                    { id: 3, name: "Last Name" },
                    { id: 4, name: "Email" },
                    { id: 5, name: "Team" },
                    { id: 6, name: "Date Joined" },
                ],
                selectedItem: { id: 5, name: "Sort" },
            },
            recordActivePage: 1,
            recordPerPage: 10,
        };
    },
    methods: {
        getItems(items) {
            const data = [...items];
            if (!this.sort) {
                return data.slice(
                    (this.recordActivePage - 1) * this.recordPerPage,
                    this.recordPerPage * this.recordActivePage
                );
            } else {
                let list = [];
                if (
                    this.sort?.name === "Magnitude" &&
                    typeof items?.[0]?.Magnitude === "object"
                ) {
                    list = data.sort((a, b) =>
                        a[this.sort.name]?.magnitude >
                        b[this.sort.name]?.magnitude
                            ? this.sort?.sort
                                ? 1
                                : -1
                            : this.sort?.sort
                            ? -1
                            : 1
                    );
                } else if (this.sort?.name === "Market") {
                    list = data.sort((a, b) =>
                        parseInt(a[this.sort.name]?.replace(/[^0-9]/gi, "")) >
                        parseInt(b[this.sort.name]?.replace(/[^0-9]/gi, ""))
                            ? this.sort?.sort
                                ? 1
                                : -1
                            : this.sort?.sort
                            ? -1
                            : 1
                    );
                } else {
                    list = data.sort((a, b) =>
                        a[this.sort.name]?.toString()?.toLowerCase() >
                        b[this.sort.name]?.toString()?.toLowerCase()
                            ? this.sort?.sort
                                ? 1
                                : -1
                            : this.sort?.sort
                            ? -1
                            : 1
                    );
                }

                return list.slice(
                    (this.recordActivePage - 1) * this.recordPerPage,
                    this.recordPerPage * this.recordActivePage
                );
            }
        },
        async perPageChanged(count) {
            this.recordPerPage = count;
        },
        async pageChanged(page) {
            this.recordActivePage = page;
        },
    },
};
</script>

<style scoped></style>
