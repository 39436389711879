<template>
    <div class="flex flex-col w-full h-screen">
        <div class="flex flex-col w-full mt-6 mb-2 sm:px-4">
            <slot name="header">
                <Header
                    :isNotAdd="origTeams.length === 0 || isNotAdd"
                    @onAdd="() => toggleSlideover(true)"
                    :title="listHeader.title"
                    :btnLabel="listHeader.btnText"
                    :count="items.length"
                    :searchbox="listHeader.searchbox"
                    :filter="origTeams.length > 0 ? listHeader.filter : null"
                    :sort="origTeams.length > 0 ? listHeader.sort : null"
                    @onSort="
                        (sort) => {
                            $emit('onSort', sort);
                            recordActivePage = 1;
                        }
                    "
                    @onFilter="
                        (filter) => {
                            $emit('onFilter', filter);
                            recordActivePage = 1;
                        }
                    "
                    :searchText="searchText"
                    :onSearchTextChange="
                        (value) => this.onSearchTextChange(value)
                    "
                />
            </slot>
        </div>
        <Loader v-if="isPageLoading" :isLoading="isPageLoading" />
        <div v-else class="flex flex-col w-full h-screen px-4 sm:px-8 space-y-2">
            <template v-if="!isLoading && (!items || items.length === 0)">
                <EmptyState
                    class="bg-white"
                    isFull
                    :description="emptyViewBinding.description"
                />
                <slot name="add"> </slot>
            </template>
            <template v-else>
                <div
                    class="flex flex-col border border-gray-300"
                    v-for="item in getItems(items)"
                    :key="item"
                >
                    <Items v-bind="item" @onClick="onClickItem(item)" />
                </div>
                <CardFooterWithPageButtons
                    class="px-0 bg-opacity-0 pb-40 sm:pb-10"
                    :onChangePerPage="(count) => perPageChanged(count)"
                    :totalRecords="items?.length"
                    :perPage="recordPerPage"
                    :currentPage="recordActivePage"
                    :onChangePage="(page) => pageChanged(page)"
                />
            </template>
        </div>
        <!--		</template>-->
    </div>
</template>

<script>
import EmptyState from "../../organisms/EmptyState/Simple/Simple";
import Header from "../../molecules/ListViewHeader/WithFilter/ListViewHeader.vue";
import Items from "../../molecules/CardListItem/CardWithBadge/CardWithBadge.vue";
import Icon from "../../atoms/Icons/Icons.vue";
import Text from "../../atoms/Text/Text.vue";
import Simple from "../../organisms/Tables/Simple/Simple.vue";
import CardFooterWithPageButtons from "../../organisms/Paginations/CardFooterWithPageButtons/CardFooterWithPageButtons.vue";
import { c } from "../../constants.js";
import Loader from "../../atoms/Loader/Loader.vue";

/**
 * - Use it to show a LinkedRecordList
 */
export default {
    components: {
        Header,
        Items,
        Text,
        Simple,
        Icon,
        CardFooterWithPageButtons,
        EmptyState,
        Loader,
    },
    props: {
        /**
         * Use it for list view items
         */
        items: {
            type: Array,
            default: () => [],
        },
        origTeams: {
            type: Array,
            default: () => [],
        },
        listHeader: {
            type: Object,
            default: () => {},
        },
        /**
         * action to get page data
         */
        getPageData: {
            type: Function,
            default: () => {},
        },
        /**
         * loading status
         */
        isLoading: {
            type: Boolean,
            default: false,
        },
        onClickItem: {
            type: Function,
            default: () => {},
        },
        toggleSlideover: {
            type: Function,
            default: () => {},
        },
        viewName: {
            type: String,
            default: "",
        },
        emptyViewBinding: {
            type: Object,
            default: () => {},
        },
        searchText: {
            type: String,
            default: "",
        },
        onSearchTextChange: {
            type: Function,
            default: () => {},
        },
        isNotAdd: {
            type: Boolean,
            default: false,
        },
        isPageLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            c,
            recordActivePage: 1,
            recordPerPage: 10,
        };
    },
    methods: {
        getItems(items) {
            return items.slice(
                (this.recordActivePage - 1) * this.recordPerPage,
                this.recordPerPage * this.recordActivePage
            );
        },
        async perPageChanged(count) {
            console.log(count, "count");
            this.recordPerPage = count;
        },
        async pageChanged(page) {
            console.log(page, "page");
            this.recordActivePage = page;
        },
        slideOver() {
            console.log("slideOver");
            this.toggleSlideover(true);
        },
    },
};
</script>
