import gql from "graphql-tag";
import { profileFragment } from "./fragments";

/*
 *
 */
export const saveProfile = gql`
  mutation saveProfile($data: ProfileUpdateInput!, $where: ProfileWhereUniqueInput) {
    saveProfile(data: $data, where: $where) ${profileFragment}
  }
`;

/*
 *
 */
export const deleteProfile = gql`
  mutation deleteProfile($where: ProfileWhereUniqueInput) {
    deleteProfile(where: $where) ${profileFragment}
  }
`;

export const archiveProfile = gql`
  mutation archiveProfile(
    $where: ProfileWhereUniqueInput
    $bulkDeleteProfileIds: [String]
  ) {
    archiveProfile(where: $where, bulkDeleteProfileIds: $bulkDeleteProfileIds)
  }
`;

export const sendOutreachEmail = gql`
  mutation sendOutreachEmail(
    $userId: String!
    $profileId: String!
    $emailTemplateId: String
    $subject: String!
    $body: String!
  ) {
    sendOutreachEmail(
      userId: $userId
      profileId: $profileId
      emailTemplateId: $emailTemplateId
      subject: $subject
      body: $body
    )
  }
`;
