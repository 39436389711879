<template>
    <div class="flex flex-col w-full h-screen">
        <div class="flex flex-col w-full mt-6 mb-2 sm:px-4">
            <slot name="header">
                <Header
                    :title="listHeader.title"
                    :isNotAdd="true"
                    :count="items.length"
                    :searchbox="listHeader.searchbox"
                    :isShowSearchBox="listHeader.showSearchbox"
                    :searchText="searchText"
                    :onSearchTextChange="
                        (value) => this.onSearchTextChange(value)
                    "
                />
            </slot>
        </div>
        <Loader v-if="isPageLoading" :isLoading="isPageLoading" />
        <div
            v-else
            class="flex flex-col w-full h-screen px-4 sm:px-8 space-y-2"
        >
            <template v-if="!isLoading && (!items || items.length === 0)">
                <EmptyState
                    class="bg-white"
                    isFull
                    :description="get(emptyViewBinding, 'description')"
                />
                <slot name="add"> </slot>
            </template>
            <template v-else>
                <Simple
                    :list="getOpportunities(items)"
                    @onClickItem="(item) => onClickItem(item)"
                    isClickable
                    :sortFields="[
                        'Opportunity',
                        'Venture',
                        'Responses',
                        'Magnitude',
                        'Market',
                    ]"
                    @onSort="(item) => (sort = item)"
                />
                <CardFooterWithPageButtons
                    class="px-0 bg-opacity-0"
                    :onChangePerPage="(count) => perPageChanged(count)"
                    :totalRecords="get(items, 'length')"
                    :perPage="recordPerPage"
                    :currentPage="recordActivePage"
                    :onChangePage="(page) => pageChanged(page)"
                />
            </template>
        </div>
    </div>
</template>

<script>
import EmptyState from "../../organisms/EmptyState/Simple/Simple";
import Header from "../../molecules/ListViewHeader/WithFilter/ListViewHeader.vue";
import Items from "../../molecules/CardListItem/CardWithSurveyResult/CardWithSurveyResult";
import Icon from "../../atoms/Icons/Icons.vue";
import Text from "../../atoms/Text/Text.vue";
import Simple from "../../organisms/Tables/Simple/Simple.vue";
import CardFooterWithPageButtons from "../../organisms/Paginations/CardFooterWithPageButtons/CardFooterWithPageButtons.vue";
import { c } from "../../constants.js";
import { get } from "lodash";
import Loader from "../../atoms/Loader/Loader.vue";

/**
 * - Use it to show a LinkedRecordList
 */
export default {
    components: {
        Header,
        Items,
        Text,
        Simple,
        Icon,
        CardFooterWithPageButtons,
        EmptyState,
        Loader,
    },
    props: {
        /**
         * Use it for list view items
         */
        items: {
            type: Array,
            default: () => [],
        },
        listHeader: {
            type: Object,
            default: () => {},
        },
        recordPerPage: {
            type: Number,
            default: 10,
        },
        /**
         * total records in table
         */
        pageTotalRecords: {
            type: Number,
            default: 0,
        },
        /**
         * current page of record table
         */
        recordActivePage: {
            type: Number,
            default: 1,
        },
        /**
         * action to get page data
         */
        getPageData: {
            type: Function,
            defaullt: () => {},
        },
        /**
         * loading status
         */
        isLoading: {
            type: Boolean,
            default: false,
        },
        onClickItem: {
            type: Function,
            default: () => {},
        },
        toggleSlideover: {
            type: Function,
            default: () => {},
        },
        viewName: {
            type: String,
            default: "",
        },
        emptyViewBinding: {
            type: Object,
            default: () => {},
        },
        searchText: {
            type: String,
            default: "",
        },
        onSearchTextChange: {
            type: Function,
            default: () => {},
        },
        isPageLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            c,
            get,
            recordActivePage: 1,
            recordPerPage: 10,
            sort: null,
        };
    },
    methods: {
        getOpportunities(items) {
            const data = [...items];
            if (!this.sort) {
                return data
                    ?.sort((a, b) =>
                        a?.Magnitude?.magnitude > b?.Magnitude?.magnitude
                            ? -1
                            : 1
                    )
                    .slice(
                        (this.recordActivePage - 1) * this.recordPerPage,
                        this.recordPerPage * this.recordActivePage
                    );
            } else {
                let list = [];
                if (
                    this.sort?.name === "Magnitude" &&
                    typeof items?.[0]?.Magnitude === "object"
                ) {
                    list = data.sort((a, b) =>
                        a[this.sort.name]?.magnitude >
                        b[this.sort.name]?.magnitude
                            ? this.sort?.sort
                                ? 1
                                : -1
                            : this.sort?.sort
                            ? -1
                            : 1
                    );
                } else if (this.sort?.name === "Market") {
                    list = data.sort((a, b) =>
                        parseInt(a[this.sort.name]?.replace(/[^0-9]/gi, "")) >
                        parseInt(b[this.sort.name]?.replace(/[^0-9]/gi, ""))
                            ? this.sort?.sort
                                ? 1
                                : -1
                            : this.sort?.sort
                            ? -1
                            : 1
                    );
                } else {
                    list = data.sort((a, b) =>
                        a[this.sort.name]?.toString()?.toLowerCase() >
                        b[this.sort.name]?.toString()?.toLowerCase()
                            ? this.sort?.sort
                                ? 1
                                : -1
                            : this.sort?.sort
                            ? -1
                            : 1
                    );
                }

                return list.slice(
                    (this.recordActivePage - 1) * this.recordPerPage,
                    this.recordPerPage * this.recordActivePage
                );
            }
        },
        onSort(header) {
            this.sort = header;
        },
        async perPageChanged(count) {
            this.recordPerPage = count;
        },
        async pageChanged(page) {
            this.recordActivePage = page;
        },
        slideOver() {
            console.log("slideOver");
            this.toggleSlideover(true);
        },
    },
};
</script>
<style scoped>
::v-deep(.add-btn) {
    min-width: 0px;
}
</style>
