import gql from "graphql-tag";
// import { PaymentMethod } from "@/store/effects/gql/user/fragments";
// import { ventureFragment } from "@/store/effects/gql/venture/fragments";
import { cohortFragment } from "@/store/effects/gql/cohort/fragments";
import { opportunityFragment } from "@/store/effects/gql/opportunity/fragments";

export const companyFragment = gql`
{
   id name username slug entityType url avatar type description
   metadata
   paymentMethods { id type expirationDate cardType last4 isValid isDefault createdAt methodType isValid image
    vendors { name token callbackUrl redirectUrl isValid checkoutUrl }
  }
   owner {id username fullName email firstName lastName
    groups{ id name type }   
    paymentMethods { id type expirationDate cardType last4 isValid isDefault createdAt methodType isValid image
      vendors { name token callbackUrl redirectUrl isValid checkoutUrl }
    }
  }
   employees {
     id user{
        id
        email
        firstName 
        lastName 
        fullName 
        avatar
        groups{id} 
        favoriteMedias{id name type} 
        storylines{id} 
        nominations{id}
        userGroups{
          id
          group{id name type}
          subgroups{id group{id name}}
          createdAt
          updatedAt
        }
     }
     title {id name}
     startDate
   }
   locations {
          id name nameLower
          products {
            id name nameLower
          }
        }
   groups {id name type groups{id name} company{id username name} createdAt}
   addresses {id name type address address2 city state postalCode country websites {id url name description}}
   storylines {id name dueDate progress status}
   media {id name type description avatar source gallery {id name type avatar}}
   productSubscriptions{id name description}
   deliverables{id linkedMedias{id approval media{id avatar source name}}}
   transactions {
    id paymentMethodToken amountPaid transactionId walletAmount response status note type createdAt
    storylines{id name status}
   }
   users { id firstName lastName email fullName groups { id name } createdAt userGroups {id group {id name}}}
   opportunities${opportunityFragment}
   ventures{id cohorts{id} name status milestones {id name message tagLabel sortOrder} targetCustomerUsers{id} contacts{id} opportunities{id frequency desireText experience name actionStep{id name} surveyOpportunities{id responses{id}} } resources{id name type body files{id source}} interviews{id} users{id firstName lastName email createdAt ventures{id name}} }
   programs{
    id name type duration cohorts${cohortFragment}
   }
   createdAt
}
`;

export const companyDefaultFragment = gql`
  {
    id
    avatar
    name
    description
    ventures {
      id
      name
      targetCustomerUsers {
        id
        isArchived
      }
      interviews {
        id
        isArchived
      }
      users {
        id
        firstName
        lastName
        email
        createdAt
      }
    }
    owner {
      id
      firstName
      lastName
      email
    }
    users {
      id
      fullName
      firstName
      lastName
      userGroups {
        id
        group {
          id
          name
        }
      }
    }
    programs {
      id
      name
      cohorts {
        id
        name
        startDate
        endDate
        ventures {
          users {
            id
            firstName
            lastName
            email
            createdAt
          }
          targetCustomerUsers {
            id
            isArchived
          }
          interviews {
            id
            isArchived
          }
          id
          name
          opportunities {
            id
            name
            outOfMarketPercentage
            lowMarketPercentage
            midMarketPercentage
            hiMarketPercentage
            magnitudeScore
            adjustedMarket
            isArchived
            surveyOpportunities {
              id
              responses {
                id
                profile {
                  id
                  user {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
