<template>
    <div class="px-2 sm:px-10 py-4 flex flex-col flex-1">
        <Loader v-if="isPageLoading" :isLoading="isPageLoading" />
        <div v-else class="flex flex-col flex-1">
            <div class="flex items-center space-x-4" v-if="venture">
                <Text
                    v-if="!isEditName"
                    color="black"
                    weight="bold"
                    size="xl"
                    class="sm:text-3xl"
                    :content="venture?.name"
                />
                <Input
                    id="venture-name"
                    v-else
                    @keyup.enter="() => onPressEnter(true)"
                    @blur="() => onSave(true)"
                    v-model:value="venture.name"
                    custom-class="text-black font-bold text-xl sm:text-3xl w-full"
                />
                <Text
                    @click="
                        $router.push({
                            name: 'Venture Settings',
                            params: { ventureId: $route.params.ventureId },
                        })
                    "
                    size="sm"
                    color="primary-600"
                    content="edit"
                    decoration="underline"
                    custom-class="cursor-pointer"
                />
            </div>
            <div class="flex justify-between items-center space-x-2 mt-2">
                <Text
                    v-if="!isEditDesc"
                    color="black"
                    weight="semibold"
                    size="sm"
                    :content="venture && venture.description"
                />
                <Input
                    id="venture-description"
                    v-else
                    @keyup.enter="() => onPressEnter(false)"
                    @blur="() => onSave(false)"
                    v-model:value="venture.description"
                    custom-class="text-black font-semibold text-sm w-full"
                />
                <Text
                    @click="
                        $router.push({
                            name: 'Venture Settings',
                            params: { ventureId: $route.params.ventureId },
                        })
                    "
                    size="sm"
                    color="primary-600"
                    content="edit"
                    decoration="underline"
                    custom-class="cursor-pointer"
                />
            </div>
            <div class="mt-10">
                <EmptyState
                    v-if="isOpenTip"
                    @onClick="() => (isShowAccelerator = true)"
                    class="bg-white"
                    isFull
                    v-bind="content.empty"
                    @onClose="() => (isOpenTip = false)"
                />
            </div>
            <div class="mt-10">
                <div class="flex items-center space-x-5">
                    <Text
                        color="black"
                        weight="bold"
                        size="2xl"
                        customClass="sm:text-2xl"
                        :content="content.research.title"
                    />
                    <Badge
                        :label="currentMilestone?.tagLabel || 'Getting Started'"
                        px="3"
                        py="1"
                        bg-color="primary-500"
                        custom-class="rounded-full"
                        text-color="white"
                        text-weight="semibold"
                    />
                </div>
                <Text
                    size="xs"
                    color="black"
                    :content="content.research.description"
                    custom-class="mt-2"
                />
                <Table
                    @onClickItem="
                        (item) =>
                            $router.push(
                                `/venture/${state.currentVenture?.id}/target-customers/${item?.id}`
                            )
                    "
                    :list="targetCustomers()"
                    class="mt-5"
                    isClickable
                />
            </div>
            <div class="mt-10" v-if="opportunities.length > 0">
                <Text
                    color="black"
                    weight="bold"
                    size="2xl"
                    customClass="sm:text-2xl"
                    :content="content.opportunity.title"
                />
                <Text
                    size="xs"
                    color="black"
                    :content="content.opportunity.description"
                    custom-class="mt-2"
                />
                <Table
                    v-if="opportunities?.length > 0"
                    :list="opportunities"
                    class="mt-5"
                />
            </div>
            <div class="mt-10">
                <div class="flex items-center space-x-5">
                    <Text
                        color="black"
                        weight="bold"
                        size="2xl"
                        customClass="sm:text-2xl"
                        :content="content.venture.title"
                    />
                    <Text
                        @click="
                            () =>
                                $router.push(
                                    `/venture/${state.currentVenture?.id}/settings`
                                )
                        "
                        size="sm"
                        color="primary-600"
                        :content="content.venture.btnText"
                        decoration="underline"
                        custom-class="cursor-pointer"
                    />
                </div>
                <EmptySimpleState
                    v-if="ventureTeams?.length === 0"
                    class="bg-white mt-3"
                    isFull
                    v-bind="{
                        title: 'Add you venture team',
                        description: 'Please add your venture team',
                        btnText: 'Add Team',
                        icon: 'LightBulbIcon',
                    }"
                    @onClick="() => (isShow = true)"
                />
                <Table
                    v-if="ventureTeams?.length > 0"
                    :list="ventureTeams"
                    class="mt-3 mb-5"
                />
                <Text
                    v-if="ventureTeams?.length > 0"
                    @click="isShow = true"
                    size="sm"
                    color="primary-600"
                    :content="content.venture.inviteBtnText"
                    decoration="underline"
                    custom-class="cursor-pointer"
                />
            </div>
        </div>

        <!-- <div class="mt-10">
            <ListViewHeader title="Resources" />
            <div class="space-y-3 mt-5">
                <EmptySimpleState
                    v-if="resources?.length === 0"
                    class="bg-white mt-3"
                    isFull
                    v-bind="{
                        title: 'Add the first resource',
                        description: 'Please add the first resource',
                        icon: 'LightBulbIcon',
                        btnText: null,
                    }"
                />
                <CardWithImage
                    v-for="idea in resources"
                    :key="idea.id"
                    v-bind="idea"
                    @onClick="
                        () =>
                            $router.push({
                                name: 'Venture Resource Detail',
                                params: {
                                    ventureId: $route.params?.ventureId,
                                    id: idea?.id,
                                },
                            })
                    "
                />
                <Text
                    size="sm"
                    color="primary-600"
                    content="More Resources"
                    decoration="underline"
                    custom-class="cursor-pointer"
                    @click="
                        $router.push({
                            name: 'Resources',
                            query: { ventureId: state.currentVenture?.id },
                        })
                    "
                />
            </div>
        </div> -->
        <InviteMember
            :is-show="isShow"
            @onClose="isShow = false"
            @onSubmit="(formGroup) => onSubmitBtn(formGroup)"
            v-bind="{
                ...c.inviteMember,
                isShow,
                isLoading,
                list: state.group.groups,
            }"
        />
        <AcceleratorModal
            :isShow="isShowAccelerator"
            @onClose="isShowAccelerator = false"
            :milestones="venture?.milestones"
            :venture="venture"
            title="Welcome to the DSCVRY accelerator!"
            textHtml="Let's get started by figuring out how many adults in physical therapy there are out there."
        />
    </div>
</template>

<script>
import Input from "../components/molecules/Inputs/Component/Component";
import EmptyState from "../components/organisms/EmptyState/Range/Range";
import EmptySimpleState from "../components/organisms/EmptyState/Simple/Simple.vue";
import ListViewHeader from "../components/molecules/ListViewHeader/WithTitleAndButton/WithTitleAndButton";
import CardWithBadge from "../components/molecules/CardListItem/CardWithBadge/CardWithBadge";
import CardWithImage from "../components/molecules/CardListItem/CardWithImage/CardWithImage";
import Table from "../components/organisms/Tables/Simple/Simple.vue";
import Text from "../components/atoms/Text/Text";
import { c } from "../components/constants.js";
import InviteMember from "../components/organisms/Modals/InviteMember/InviteMember.vue";
import { get, upperFirst, groupBy, mapValues, size, max } from "lodash";
import { _ } from "vue-underscore";
import AcceleratorModal from "../components/organisms/Modals/AcceleratorModal/AcceleratorModal.vue";
import Badge from "../components/molecules/Badge/Basic/Basic";
import numeral from "numeral";
import { Settings } from "../../settings";
import { json } from "overmind";
import Loader from "../components/atoms/Loader/Loader.vue";

export default {
    components: {
        EmptyState,
        EmptySimpleState,
        ListViewHeader,
        CardWithImage,
        CardWithBadge,
        Text,
        InviteMember,
        Table,
        Input,
        AcceleratorModal,
        Badge,
        Loader,
    },
    data() {
        return {
            content: c.range,
            isShow: false,
            isOpenTip: true,
            isLoading: false,
            get,
            c,
            venture: null,
            resources: [],
            isEditName: false,
            isEditDesc: false,
            isShowAccelerator: false,
            currentMilestone: null,
            userSurveys: [],
            inviteLink: `https://${Settings.mainDomain}/#/register-user?v=${this.$route.params?.ventureId}`,
            isPageLoading: false,
        };
    },
    methods: {
        targetCustomers() {
            const data = [];

            if (
                this?.venture?.targetCustomerUsers?.filter(
                    (t) => !t?.isArchived
                )
            ) {
                [
                    ...this.venture.targetCustomerUsers?.filter(
                        (t) => !t?.isArchived
                    ),
                ].map((customer) => {
                    var scheduledInterviews = 0,
                        completedInterviews = 0;
                    customer?.contacts
                        ?.filter((o) => !o?.isArchived)
                        ?.filter((c) =>
                            c?.interviews?.find(
                                (interview) => interview?.scheduledDateTime
                            )
                        )
                        .map((i) => {
                            scheduledInterviews += 1;
                            if (
                                i.status === "INTERVIEW_COMPLETE" ||
                                i.status === "INTERVIEW_THANK_YOU_SENT" ||
                                i.status === "DAY_OF_REMINDER_SENT" ||
                                i.status === "SURVEY_REQUESTED" ||
                                i.status === "SURVEY_COMPLETE" ||
                                i.status === "SURVEY_FOLLOW_UP" ||
                                i.status === "SURVEY_THANK_YOU_SENT" ||
                                i.status === "OPEN_TO_TALK"
                            )
                                completedInterviews += 1;
                            return i;
                        });
                    data.push({
                        "Target Customer": upperFirst(customer?.name || ""),
                        Opportunities:
                            customer?.opportunities?.filter(
                                (o) => !o?.isArchived
                            )?.length || 0,
                        Contacts:
                            customer?.contacts?.filter((o) => !o?.isArchived)
                                ?.length || 0,
                        "Scheduled Interviews": scheduledInterviews,
                        "Completed Interviews": completedInterviews,
                        "Survey Responses": max(
                            Object.values(
                                mapValues(
                                    groupBy(
                                        this.userSurveys?.filter(
                                            (c) =>
                                                customer?.id ===
                                                c?.survey?.targetCustomerUser
                                                    ?.id
                                        ),
                                        "opportunity.id"
                                    ),
                                    size
                                ),
                                "this.userSurveys FLEX"
                            )
                        ),
                        id: customer?.id,
                    });
                });
            }
            return data;
        },
        onPressEnter(isName) {
            if (isName) {
                document.getElementById("venture-name").blur();
            } else {
                document.getElementById("venture-description").blur();
            }
        },
        async onSave(isName) {
            this.isEditDesc = false;
            this.isEditName = false;
            await this.actions.venture.saveVenture({
                where: { id: this.$route.params?.ventureId },
                data: isName
                    ? { name: this.venture?.name }
                    : { description: this.venture?.description },
            });
            const ventures = await this.actions.venture.getVentures({
                where: { id: this.$route.params?.ventureId },
                getValues: true,
                all: true,
                query: "ventureDetail",
            });
            this.venture = ventures[0];
            if (this.venture?.milestones) {
                this.currentMilestone = json(this.venture?.milestones)?.sort(
                    (a, b) => (a?.sortOrder > b?.sortOrder ? -1 : 1)
                )?.[0];
            }
        },
        async onSubmitBtn(item) {
            if (item?.length > 0) {
                const { inviteContact } = await this.actions.user.inviteContact(
                    {
                        contacts: [{ email: item[0].value }],
                        userId: this.state.currentUser?.id,
                        extraData: {
                            ventureId: this.venture?.id,
                            ventureName: this.venture?.name,
                            groupId: item[1]?.selectedItem?.id,
                            username: this.state.currentUser?.username,
                            inviteLink: this.inviteLink,
                            fullName:
                                this.state.currentUser?.firstName +
                                " " +
                                this.state.currentUser?.lastName,
                        },
                    }
                );
                if (inviteContact) {
                    this.isShow = false;
                    setTimeout(
                        () =>
                            this.actions.alert.showSuccess({
                                message:
                                    "You've successfully sent your invitation",
                                title: "Invitation sent",
                            }),
                        1000
                    );
                }
            }
        },
        async onInitialize() {
            const ventures = await this.actions.venture.getVentures({
                where: { id: this.$route.params?.ventureId },
                getValues: true,
                all: true,
                query: "ventureDetail",
            });
            this.venture = ventures[0];
            this.userSurveys = await this.actions.userSurvey.getUserSurveys({
                where: {
                    isArchived_not: true,
                },
                getValues: true,
                all: true,
                fragments: `{id  opportunity {id isArchived} profile {id} survey {id targetCustomerUser {id isArchived}}}`,
            });
            console.log(this.userSurveys);
            this.currentMilestone = json(this.venture?.milestones)?.sort(
                (a, b) => (a?.sortOrder > b?.sortOrder ? -1 : 1)
            )?.[0];
            // if (
            //     this?.venture?.targetCustomerUsers?.filter(
            //         (t) => !t?.isArchived
            //     )
            // ) {
            //     let surveyData = [];
            //     for (
            //         let i = 0;
            //         i <
            //         this.venture.targetCustomerUsers?.filter(
            //             (t) => !t?.isArchived
            //         )?.length;
            //         i++
            //     ) {
            //         const targetCustomer =
            //             this.venture.targetCustomerUsers?.filter(
            //                 (t) => !t?.isArchived
            //             )[i];
            //         const data = await this.actions.userSurvey.getUserSurveys({
            //             where: {
            //                 isArchived_not: true,
            //                 survey: {
            //                     targetCustomerUser: { id: targetCustomer?.id },
            //                 },
            //             },
            //             getValues: true,
            //         });
            //         surveyData = [...surveyData, ...data];
            //     }
            //     this.venture.surveyData = surveyData;
            // }
            // if (this.venture?.milestones) {
            //     this.resources = await this.actions.resource.getResources({
            //         // where: {
            //         //   milestone_some: { id_in: _.pluck(this.venture?.milestones, "id") },
            //         // },
            //         getValues: true,
            //     });
            //     this.currentMilestone = json(this.venture?.milestones).sort(
            //         (a, b) => (a?.sortOrder > b?.sortOrder ? -1 : 1)
            //     )?.[0];
            // }
            await this.actions.milestone.getMilestones();
            console.log(this.state.milestone.milestones, "milestones");
        },
    },
    computed: {
        ventureTeams() {
            const team = [];
            if (this?.venture?.users) {
                [...this.venture.users].map((user) => {
                    team.push({
                        name: user.firstName + " " + user.lastName,
                        role:
                            user?.id === this.venture?.createdBy?.id
                                ? "Owner"
                                : user?.groups?.[1]?.name,
                        contacts: this.venture.contacts?.filter(
                            (c) => c?.isArchived != true
                        )?.length,
                        interviews:
                            this.venture?.contacts?.filter(
                                (c) =>
                                    !c?.isArchived &&
                                    c?.interviews?.find(
                                        (interview) =>
                                            interview?.scheduledDateTime
                                    )
                            )?.length || 0,
                        opportunities: this.venture.opportunities?.filter(
                            (c) => c?.isArchived != true
                        )?.length,
                    });
                });
            }
            return team;
        },
        resources() {
            const resourceData = [];
            if (this.resources) {
                [...this.resources].map((r) => {
                    resourceData.push({
                        id: r.id,
                        title: r.name,
                        imgSrc: r?.directLink?.source,
                        desc: r?.body,
                        items: [
                            {
                                icon: "LinkIcon",
                                label: r.type,
                            },
                            {
                                label: "Getting Started",
                            },
                        ],
                        customClass: "",
                    });
                });
            }
            return resourceData;
        },
        opportunities() {
            const opportunityData = [];
            if (
                this?.venture?.opportunities?.filter(
                    (o) => o?.isArchived !== true
                )
            ) {
                [...this?.venture?.opportunities]
                    ?.filter((o) => o?.isArchived !== true)
                    ?.map((opportunity) => {
                        let responses = 0;
                        opportunity?.surveyOpportunities?.map(
                            (so) =>
                                (responses +=
                                    so?.responses?.filter((r) => !r?.isArchived)
                                        ?.length || 0)
                        );
                        if (responses === 0) return false;
                        opportunityData.push({
                            Opportunity: opportunity?.name || "",
                            "Target Customer":
                                opportunity?.targetCustomerUser?.name || "",
                            Responses: this.userSurveys?.filter(
                                (u) => u?.opportunity?.id === opportunity?.id
                            )?.length,
                            Magnitude: {
                                out: opportunity?.outOfMarketPercentage || 0,
                                low: opportunity?.lowMarketPercentage || 0,
                                mid: opportunity?.midMarketPercentage || 0,
                                hi: opportunity?.hiMarketPercentage || 0,
                                magnitude: opportunity?.magnitudeScore,
                            },
                            Market: numeral(opportunity?.adjustedMarket).format(
                                "0,0"
                            ),
                        });
                    });
            }

            console.log(opportunityData, "opportunityData");
            return opportunityData;
        },
    },
    async created() {
        this.isPageLoading = true;
        await this.onInitialize();
        if (this.state.group?.groups?.length === 0) {
            await this.actions.group.getGroups({
                where: { type: "ROLE", venture: { id: this.venture?.id } },
            });
        }
        this.isPageLoading = false;
    },
};
</script>
