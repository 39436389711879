<template>
    <div>
        <!-- <Header v-bind="header" class="px-0" /> -->
        <div class="">
            <div class="">
                <div
                    v-if="list && list.length > 0"
                    class="overflow-x-auto inline-block w-full py-2 align-middle"
                >
                    <div class="ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table
                            class="min-w-full divide-y divide-gray-300 overflow-x-scroll"
                        >
                            <thead class="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        class="relative w-12 px-6 sm:w-16 sm:px-8"
                                        v-if="isMultipleSelection"
                                    >
                                        <input
                                            type="checkbox"
                                            class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500 sm:left-6"
                                            :checked="
                                                get(selected, 'length') ===
                                                get(
                                                    getSortAndFiltered(list),
                                                    'length'
                                                )
                                            "
                                            :indeterminate="
                                                get(selected, 'length') !== 0 &&
                                                get(selected, 'length') !==
                                                    get(
                                                        getSortAndFiltered(
                                                            list
                                                        ),
                                                        'length'
                                                    )
                                            "
                                            @click.stop="onSelected('all')"
                                        />
                                    </th>
                                    <th
                                        v-for="(item, id) in headers"
                                        :key="id"
                                        scope="col"
                                        class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                        <div
                                            class="flex items-center space-x-2 group"
                                        >
                                            <a class="whitespace-nowrap">
                                                {{ item.label }}
                                            </a>
                                            <span
                                                v-if="
                                                    item.label &&
                                                    item.label.length > 0
                                                "
                                                class="ml-2 flex items-center rounded text-gray-400 relative opacity-0 group-hover:opacity-100"
                                            >
                                                <Icon
                                                    v-if="item.isSort"
                                                    @click="
                                                        () => {
                                                            item.isAscending =
                                                                !item.isAscending;
                                                            selectedHeader =
                                                                item;
                                                        }
                                                    "
                                                    :name="
                                                        item.isAscending
                                                            ? 'ChevronDownIcon'
                                                            : 'ChevronUpIcon'
                                                    "
                                                    custom-class="cursor-pointer group-hover:text-gray-400"
                                                    size="5"
                                                    color="gray-50"
                                                    hoverTextColor="gray-300"
                                                />
                                                <div class="absolute">
                                                    <Dropdown
                                                        v-if="item.isFilter"
                                                        :icon="'FilterIcon'"
                                                        :menuItemClicked="
                                                            (filter) =>
                                                                onClickItem(
                                                                    filter,
                                                                    item
                                                                )
                                                        "
                                                        class="bg-gray-50 group-hover:text-gray-400"
                                                        :items="
                                                            getFilterList(item)
                                                        "
                                                    />
                                                </div>
                                            </span>
                                        </div>
                                    </th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody
                                class="divide-y divide-gray-200 bg-white overflow-scroll h-full"
                            >
                                <tr
                                    v-for="(item, i) in getSortAndFiltered(
                                        list
                                    )"
                                    :key="i"
                                    @click="
                                        () => {
                                            if (isRow)
                                                $emit('onClickItem', item, id);
                                        }
                                    "
                                >
                                    <td
                                        class="relative w-12 px-6 sm:w-16 sm:px-8"
                                        v-if="isMultipleSelection"
                                    >
                                        <!-- <div class="absolute inset-y-0 left-0 w-0.5 bg-primary-600"></div> -->
                                        <input
                                            type="checkbox"
                                            :checked="
                                                selected &&
                                                selected.find(
                                                    (s) => s.id === item.id
                                                )
                                            "
                                            @click.stop="onSelected(item)"
                                            class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500 sm:left-6"
                                        />
                                    </td>
                                    <td
                                        v-for="(header, index) in headers"
                                        :key="index"
                                        :class="isRow && 'cursor-pointer'"
                                        class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                                    >
                                        <Badge
                                            v-if="
                                                [
                                                    'Relationship',
                                                    'Status',
                                                    'Magnitude',
                                                ].find(
                                                    (key) =>
                                                        key ===
                                                        get(header, 'label')
                                                )
                                            "
                                            px="3"
                                            py="1"
                                            :bg-color="
                                                getBgColor(
                                                    get(header, 'label'),
                                                    item
                                                )
                                            "
                                            custom-class="rounded-full"
                                            text-color="white"
                                            text-weight="md"
                                            :label="item[header.label]"
                                        />
                                        <div
                                            v-else
                                            v-html="item[header.label]"
                                        ></div>
                                    </td>
                                    <td
                                        v-if="!isRow"
                                        class="sticky right-0 relative whitespace-nowrap py-4 pl-3 pr-4 text-right sm:pr-6 bg-white"
                                    >
                                        <Text
                                            @click="
                                                $emit('onClickItem', item, id)
                                            "
                                            content="View"
                                            color="primary-600"
                                            hoverColor="primary-900"
                                            size="sm"
                                            weight="medium"
                                            custom-class="cursor-pointer"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonWithIcon from "../../../molecules/Button/WithIcon/WithIcon.vue";
import Link from "../../../atoms/Link/Link.vue";
import Text from "../../../atoms/Text/Text.vue";
import Icon from "../../../atoms/Icons/Icons.vue";
import { capitalize, get } from "lodash";
import Dropdown from "../../../molecules/Dropdown/DropdownWithMinIcon/DropdownWithMinIcon.vue";
import Empty from "../../EmptyState/Simple/Simple.vue";
import InputWithLeadingIcon from "../../../molecules/Inputs/InputWithLeadingIcon/InputWithLeadingIcon.vue";
import CardFooterWithPageButtons from "../../Paginations/CardFooterWithPageButtons/CardFooterWithPageButtons.vue";
import Header from "../../../molecules/TableViewHeader/Simple/TableViewHeader.vue";
import Badge from "../../../molecules/Badge/Basic/Basic.vue";

/**
 * - Use it to show a simple table with sortabel headings
 */
export default {
    components: {
        ButtonWithIcon,
        Link,
        Text,
        Icon,
        Dropdown,
        Empty,
        InputWithLeadingIcon,
        CardFooterWithPageButtons,
        Header,
        Badge,
    },
    data() {
        return {
            /**
             * Generate dynamic headers according to list item
             */
            filterColumns: ["Role"],
            selectedHeader: null,
            get,
            selected: [],
        };
    },
    props: {
        /**
         * Use it for table list
         */
        list: {
            type: Array,
            default: () => [],
        },
        /**
         * Use it for table title
         */
        title: {
            type: String,
            default: "",
        },
        /**
         * Use it for table description
         */
        description: {
            type: String,
            default: "",
        },
        /**
         * Use it for button text
         */
        btnText: {
            type: String,
            default: "",
        },
        /**
         * Use it for button action
         */
        onClick: {
            type: Function,
            default: () => {},
        },
        filterList: {
            type: Array,
            default: () => [],
        },
        empty: {
            type: Object,
            default: () => {},
        },
        searchbox: {
            type: Object,
            default: () => {},
        },
        pagination: {
            type: Object,
            default: () => {},
        },
        headers: {
            type: Array,
            default: () => [],
        },
        isRow: {
            type: Boolean,
            default: false,
        },
        isMultipleSelection: {
            type: Boolean,
        },
    },
    methods: {
        onSelected(item) {
            console.log(item, "item");
            if (item === "all") {
                this.selected = this.getSortAndFiltered(this.list);
            } else {
                const index = this.selected.findIndex((i) => i.id === item.id);
                if (index > -1) {
                    this.selected.splice(index, 1);
                } else {
                    this.selected.push(item);
                }
            }
            console.log(this.selected);
            this.$emit("onSelected", this.selected, () => {
                this.selected = [];
            });
        },
        getBgColor(name, item) {
            if (name === "Magnitude") {
                const value = item[name];
                if (value < 1) {
                    return "gray-400";
                } else if (value >= 1 && value < 25) {
                    return "orange-400";
                } else if (value >= 25 && value < 50) {
                    return "amber-300";
                } else if (value >= 50) {
                    return "green-400";
                }
            } else {
                return "primary-500";
            }
        },
        onClickItem(filter, item) {
            if (filter.name === "Clear Filter") {
                item.filterValue = null;
            } else {
                item.filterValue = filter;
            }
        },
        getSortAndFiltered(items) {
            let data = [...items];
            if (this.selectedHeader?.label) {
                if (this.selectedHeader?.isAscending) {
                    data = data.sort((a, b) =>
                        a?.[this.selectedHeader?.label] >
                        b?.[this.selectedHeader?.label]
                            ? -1
                            : 1
                    );
                } else {
                    data = data.sort((a, b) =>
                        a?.[this.selectedHeader?.label] >
                        b?.[this.selectedHeader?.label]
                            ? 1
                            : -1
                    );
                }
            }
            this.headers?.map((header) => {
                if (header?.isFilter) {
                    if (header?.filterValue?.name) {
                        data = data.filter(
                            (d) =>
                                d?.[header?.label] === header?.filterValue?.name
                        );
                    }
                }
            });
            return data;
        },
        getFilterList(header) {
            const filters = [];
            this.list?.map((item) => {
                if (
                    !filters?.find(
                        (filter) => filter?.name === item?.[header?.label]
                    )
                ) {
                    filters.push({ name: item?.[header?.label] });
                }
            });
            filters.push({ name: "Clear Filter" });
            return filters;
        },
    },
};
</script>
