<template>
    <SideOver
        v-if="showSwiper"
        @onClickEnter="onClickEnter"
        isLarge
        :title="title"
        :is-show="isShow"
        isMultiple
        @onClose="() => onClose()"
        :label="getContent()"
        :isLoading="isLoading"
        :buttons="getButtons()"
    >
        <swiper
            v-if="showSwiper"
            :controller="{ control: swiper }"
            :simulateTouch="false"
            :modules="[Virtual, Controller]"
            @swiper="setSwiper"
            :slides-per-view="1"
            :space-between="10"
            :initialSlide="activeIndex && parseInt(activeIndex)"
        >
            <swiper-slide class="relative">
                <div class="flex-1 flex-col p-6 h-full overflow-y-auto">
                    <Text
                        size="sm"
                        weight="semibold"
                        color="gray-700"
                        content="You can upload contact information with the following fields:"
                    />
                    <ul role="list" class="mt-1 list-disc pl-5 pb-12">
                        <li v-for="item in fields" :key="item" class="text-sm">
                            {{ item }}
                        </li>
                    </ul>
                    <div id="csvFileForm">
                        <div class="">
                            <Text
                                size="sm"
                                weight="semibold"
                                color="gray-700"
                                content="Select CSV File"
                                custom-class="mb-2"
                            />
                            <FileUploader
                                v-bind="{
                                    emptyText: 'Select a file',
                                    emptyIcon: 'CloudUploadIcon',
                                    editText: 'Edit',
                                    fileTypes: '.csv',
                                    name: 'csvFile',
                                }"
                                v-model:file="csvFile"
                            />
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="relative">
                <div class="flex-1 flex-col p-6 h-full overflow-y-auto">
                    <div class="mb-5">
                        We found <b>{{ getFields.length }} columns</b> and
                        <b>{{ csvData.split("\n").length - 1 }} rows</b> in your
                        file
                    </div>
                    <Select
                        label="Target Customer *"
                        :items="targetCustomerUsers"
                        v-model:selectedItem="targetCustomer"
                    />
                    <SwitchGroup>
                        <div
                            class="flex items-center mt-5 pb-10 mb-4 border-b-2"
                        >
                            <SwitchLabel class="mr-4"
                                >File has column headers (like "First Name")
                            </SwitchLabel>
                            <Switch
                                v-model="isSecondRowHeader"
                                :class="
                                    isSecondRowHeader
                                        ? 'bg-primary-700'
                                        : 'bg-gray-200'
                                "
                                class="relative inline-flex h-6 w-11 items-center rounded-full"
                            >
                                <span class="sr-only"
                                    >csv file header field</span
                                >
                                <span
                                    :class="
                                        isSecondRowHeader
                                            ? 'translate-x-6'
                                            : 'translate-x-1'
                                    "
                                    class="inline-block h-4 w-4 transform rounded-full bg-white transition"
                                />
                            </Switch>
                        </div>
                    </SwitchGroup>

                    <div
                        class="pt-6 pb-6"
                        v-for="(field, index) in getFields"
                        :key="index"
                    >
                        <Select
                            :label="field.label"
                            :items="field.options"
                            v-model:selectedItem="selectedFields[field.key]"
                        />
                    </div>
                    <div
                        v-if="isSaving"
                        class="absolute bottom-0 bg-white py-5 border-t left-4 right-4 z-50"
                    >
                        <div
                            class="w-full h-2 rounded-full bg-gray-300 relative"
                        >
                            <div
                                class="absolute left-0 rounded-full h-2 bg-bg_primary"
                                :style="{ width: progress + '%' }"
                            ></div>
                        </div>
                        <Text
                            size="xs"
                            weight="semibold"
                            color="gray-400"
                            v-html="
                                `We're working on importing your contacts. Please don't close this window.<br/>It can take a few minutes with larger files.`
                            "
                            customClass="text-center mt-3"
                        />
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </SideOver>
</template>

<script>
import Text from "../../../atoms/Text/Text.vue";
import Icon from "../../../atoms/Icons/Icons.vue";
import Input from "../../../molecules/Inputs/Component/Component.vue";
import Textarea from "../../../molecules/Textareas/Simple/Simple";
import Select from "../../../molecules/Selects/Component/Component.vue";
import Button from "../../../atoms/Button/Button.vue";
import SideOver from "../../SideOvers/WithBackgroundOverlay/WithBackgroundOverlay.vue";
import AutoComplete from "../../../molecules/AutoComplete/AutoComplete";
import MultiSelectDropdown from "../../../molecules/Dropdown/MultiSelectDropdown/MultiSelectDropdown";
import FileUploader from "../../../molecules/FilePickers/SimpleWithEmptyStateAndImagePreview/SimpleWithEmptyStateAndImagePreview";
import Simple from "../../../organisms/Tables/Simple/Simple.vue";
import { ref, watch, onMounted, nextTick } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Controller, Virtual } from "swiper";
import "swiper/swiper.min.css";
import SurveyForm from "../../SurveyForm/SurveyForm.vue";
import { Switch } from "@headlessui/vue";
import { upperFirst } from "lodash";

export default {
    components: {
        Text,
        Icon,
        Input,
        Select,
        Button,
        SideOver,
        Textarea,
        Swiper,
        SwiperSlide,
        SurveyForm,
        AutoComplete,
        MultiSelectDropdown,
        Simple,
        FileUploader,
        Switch,
    },
    data() {
        return {
            csvData: "",
            contactFields: [
                { id: 0, name: "First Name", value: "firstName" },
                { id: 1, name: "Last Name", value: "lastName" },
                { id: 2, name: "Email", value: "email" },
                { id: 3, name: "Phone", value: "phone" },
                { id: 4, name: "Facebook", value: "facebook" },
                { id: 5, name: "Twitter", value: "twitter" },
                { id: 6, name: "Instagram", value: "instagram" },
                { id: 7, name: "Tiktok", value: "tiktok" },
                { id: 8, name: "Linkedin", value: "linkedin" },
                { id: 9, name: "Ignore this field", value: "ignore" },
            ],
            fields: [
                "First name or username (required)",
                "Last name",
                "Email",
                "Phone",
                "Facebook",
                "Twitter",
                "Instagram",
                "Tiktok",
                "Linkedin",
            ],
        };
    },
    computed: {
        getFields() {
            var fields = [];
            if (this.csvData.trim() !== "") {
                const rows = this.csvData.split("\r\n");
                var row = "";
                if (!this.isSecondRowHeader && rows[1]) row = rows[1];
                else if (rows[0]) row = rows[0];
                if (row) {
                    fields = row.split(",").map((item) => {
                        return {
                            key: item,
                            label: `" ${item} " maps to...`,
                            options: this.contactFields,
                        };
                    });
                }
            }
            return fields;
        },
    },
    props: {
        isShow: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: "",
        },
        content: {
            type: String,
            default: "",
        },
        isLoading: {
            type: Boolean,
            default: true,
        },
        isSaving: {
            type: Boolean,
            default: false,
        },
        modelKey: {
            type: String,
            default: "id",
        },
        finalData: {
            type: Object,
            default: () => {},
        },
        targetCustomerUsers: {
            type: Array,
            default: () => [],
        },
        targetCustomerUser: {
            type: Object,
            default: () => {},
        },
        progress: {
            type: Number,
            default: 0,
        },
    },
    watch: {
        isShow: function (val) {
            if (val) {
                setTimeout(() => (this.showSwiper = true), 10);
            } else {
                setTimeout(() => (this.showSwiper = false), 700);
            }
        },
        csvFile: function (val) {
            var self = this;
            var reader = new FileReader();
            reader.addEventListener("load", function (e) {
                self.csvData = e.target.result;
                console.log(self.csvData, "data");
            });
            reader.readAsBinaryString(val);
        },
        targetCustomerUsers: function (val) {
            this.targetCustomer = this.targetCustomerUser?.id
                ? this.targetCustomerUser
                : val && val[0]
                ? val[0]
                : null;
        },
        isSecondRowHeader: function (val) {
            console.log(val, "val");
            this.selectedFields = {};
        },
    },
    setup(props, { emit }) {
        const swiper = ref(null);
        const formRefs = ref([]);
        const selectedFields = ref({});
        const targetCustomer = ref(null);
        let savedFormData = ref(props.finalData || {});
        const isValid = ref(false);
        const setSwiper = (e) => {
            swiper.value = e;
        };
        const activeIndex = ref(0);
        const showSwiper = ref(false);
        const show = ref(false);
        const csvFile = ref(null);
        const isSecondRowHeader = ref(true);
        const onClose = () => {
            emit("onClose");
            setTimeout(() => (showSwiper.value = false), 500);
        };
        const onNext = (isSkip) => {
            swiper.value.slideTo(activeIndex.value + 1);
            activeIndex.value = activeIndex.value + 1;
        };
        const onBack = () => {
            // const types = ['EMPLOYEES', 'BUSINESSES', 'ORGANIZATIONS', 'GOVERNMENT'];
            console.log(activeIndex, "activeIndex");
            swiper.value.slideTo(activeIndex.value - 1);
            activeIndex.value = activeIndex.value - 1;
        };
        const activeBtnText = () => {
            return activeIndex.value == 1 ? "Import" : "Next";
        };
        const getButtons = () => {
            if (activeIndex.value === 0) {
                return [
                    {
                        label: "Next",
                        type: "primary",
                        isLoading: props.isSaving,
                        isDisabled: !csvFile.value,
                        action: () => onSave(),
                    },
                ];
            } else {
                return [
                    {
                        label: "Back",
                        type: "secondary",
                        isLoading: false,
                        action: () => onBack(),
                    },
                    {
                        label: activeBtnText(),
                        type: "primary",
                        isLoading: props.isSaving,
                        isDisabled: !targetCustomer.value,
                        action: () => onSave(),
                    },
                ];
            }
        };
        const csvToJSON = (csv) => {
            var lines = csv?.split("\n");
            var result = [];
            var headers;
            headers = lines[isSecondRowHeader.value ? 0 : 1].split(",");

            for (
                var i = isSecondRowHeader.value ? 1 : 0;
                i < lines.length;
                i++
            ) {
                var obj = {};

                if (!lines[i]) {
                    continue;
                }

                var words = lines[i].split(",");
                for (var j = 0; j < words.length; j++) {
                    obj[headers[j]?.trim()] = words[j];
                }

                result.push(obj);
            }
            return result;
        };
        const onSave = () => {
            console.log("SAVE", activeIndex.value);
            if (activeIndex.value === 1) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    const contacts = [];
                    console.log(csvToJSON(e.target.result), e.target.result);
                    [...csvToJSON(e.target.result)].map((item) => {
                        const contact = {};
                        for (var property in selectedFields.value) {
                            if (
                                selectedFields.value[property].value !==
                                "ignore"
                            ) {
                                contact[selectedFields.value[property].value] =
                                    item[property];
                            }
                        }
                        contacts.push(contact);
                    });
                    console.log(contacts, "contacts");
                    emit(
                        "saveForm",
                        {
                            contacts,
                            targetCustomer: targetCustomer.value,
                        },
                        true
                    );
                };
                reader.readAsBinaryString(csvFile.value);
            } else {
                onNext();
            }
        };

        const assignRef = (el, index) => {
            if (index >= formRefs.value.length) {
                formRefs.value.push(el);
            } else {
                formRefs.value[index] = el;
            }
            return formRefs.value[index];
        };

        const onValidate = (val) => {
            isValid.value = !val;
        };

        const getProblems = () => {
            const items = [];
            props.finalData?.reasonThird?.map((problem) => {
                items.push(problem?.improvement);
            });
            return items.join(", ");
        };

        const getContent = () => {
            let content = null;
            if (activeIndex.value == 0)
                content = `Select .csv file to upload a list of new contacts. On the next step, we'll ask you to map the fields`;
            if (activeIndex.value == 1)
                content = `Let's map the fields from your file`;
            return content ? content : props.content;
        };

        onMounted(() => {
            setTimeout(() => {
                show.value = props.isShow;
            }, 50);
        });
        const onClickEnter = () => {
            if (csvFile.value) {
                onSave();
            }
        };
        return {
            selectedFields,
            targetCustomer,
            show,
            isFocusFirst: true,
            isFocusSecond: true,
            isFocusThird: true,
            csvFile,
            isSecondRowHeader,
            setSwiper,
            swiper,
            activeIndex,
            showSwiper,
            onClose,
            onNext,
            onBack,
            getButtons,
            onSave,
            Controller,
            Virtual,
            formRefs,
            assignRef,
            onValidate,
            savedFormData,
            upperFirst,
            getProblems,
            onClickEnter,
            getContent,
        };
    },
};
</script>
<style scoped>
.swiper {
    height: 100%;
}
</style>
<style>
.select-inline {
    display: flex;
    align-items: center;
}

.select-inline label {
    margin-bottom: 0 !important;
    margin-right: 20px;
    font-weight: 500 !important;
}

.select-inline div {
    flex: 1;
}
</style>
