<template>
    <div class="w-full h-screen flex flex-col flex-1">
        <DetailViewHeader
            :title="program?.name"
            :options="['Archive']"
            :backLabel="'Programs'"
            :on-back="() => $router.go(-1)"
            :actionUrl="''"
            :actionLabel="''"
            :badge-text="program?.type"
            :isActive="true"
            customClass="mx-2 sm:mx-6"
            class="mt-4"
            @onClickItem="onClickMenu"
        />
        <Loader v-if="isPageLoading" :isLoading="isPageLoading" />
        <SubNavigationBar
            @onSelect="
                (item) => {
                    selectedTab = item;
                    keyword = null;
                }
            "
            :hide-icon="false"
            :tabs="getTabItems()"
            customClass="mt-4 mx-0 sm:mx-8"
            v-else
        />
        <div v-if="!isPageLoading" class="flex flex-col flex-1">
            <div class="space-y-10" v-if="selectedTab.name === 'Details'">
                <div
                    class="sm:mx-8 grid grid-cols-1 lg:grid-cols-7 gap-x-7 pt-10"
                >
                    <div class="col-span-2">
                        <div class="flex justify-between items-center">
                            <Text
                                size="xl"
                                weight="semibold"
                                color="gray-900"
                                custom-class="md:font-bold"
                                :content="'Program Details'"
                            />
                        </div>
                        <div>
                            <!-- <p class="leading-4 mt-4"><Text size="xs" color="black" weight="base"
							:content="`Opportunities are things that people do. Add things like problems and solutions to bring this to life.`"
							element="span" /></p> -->
                        </div>
                    </div>
                    <div class="col-span-5">
                        <div class="h-fit rounded-t-md">
                            <div
                                class="px-5 py-8 grid grid-cols-2 gap-x-4 gap-y-5 bg-white border-t border-l border-r border-gray-300 rounded-t-md"
                            >
                                <div
                                    v-for="field in formGroup[0].fields"
                                    :key="field.id"
                                    :class="`col-span-${field.cols}`"
                                >
                                    <Input
                                        v-if="
                                            field.inputType === 'TEXT' ||
                                            field.inputType === 'NUMBER'
                                        "
                                        :label="field.label"
                                        :type="field.inputType.toLowerCase()"
                                        :placeholder="field.placeholder"
                                        v-model:value="
                                            formData[field.modelField]
                                        "
                                    />
                                    <Select
                                        v-else-if="
                                            field.inputType === 'DROPDOWN'
                                        "
                                        :label="field.label"
                                        :placeholder="field.placeholder"
                                        v-model:selected-item="
                                            formData[field.modelField]
                                        "
                                        :items="JSON.parse(field.options)"
                                    />
                                </div>
                            </div>
                            <div
                                class="flex flex-row justify-between w-full bg-gray-100 border-l border-r border-t border-b rounded-b-md border-gray-300"
                            >
                                <div />
                                <div
                                    class="flex flex-row items-end justify-end w-1/4 my-4 mr-4 sm:mr-3 lg:mr-5"
                                >
                                    <Button
                                        content="Cancel"
                                        :variant="null"
                                        customClass="bg-white text-black underline px-2 bg-gray-100"
                                    />
                                    <Button
                                        content="Save"
                                        variant="primary"
                                        customClass="ml-4 px-4"
                                        :isLoading="isLoading"
                                        :click="saveProgramForm"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="sm:mx-8 grid grid-cols-1 lg:grid-cols-7 gap-x-7 pt-10">
                <div class="col-span-2">
                    <div class="flex justify-between items-center">
                        <Text
                            size="xl"
                            weight="semibold"
                            color="gray-900"
                            custom-class="md:font-bold"
                            :content="'Program Admins'"
                        />
                    </div>
                    <div>
                        <p class="leading-4 mt-4">
                            <Text
                                size="xs"
                                color="black"
                                weight="base"
                                :content="`These people can access the venture team accounts that are participating and add/update their research information.`"
                                element="span"
                            />
                        </p>
                    </div>
                </div>
                <div class="col-span-5">
                    <div
                        class="bg-gray-100 border rounded-md px-6 py-4 shadow-lg mb-10"
                    >
                        <div class="">
                            <div
                                class="bg-white border-t border-b border-gray-300 divide-y divide-gray-300 mt-4"
                            >
                                <div
                                    v-for="contact in contacts"
                                    :key="contact.type"
                                    class="grid grid-cols-4 py-3 px-2"
                                >
                                    <div class="col-span-2">
                                        <Text
                                            size="sm"
                                            weight="semibold"
                                            color="black"
                                            :content="contact.value"
                                        />
                                    </div>
                                    <div class="col-span-1">
                                        <Badge
                                            :label="contact.type"
                                            :icon-name="contact.icon"
                                            isLeft
                                            bg-color="gray-500"
                                            is-rounded
                                            px="3"
                                            py="1"
                                            text-color="white"
                                        />
                                    </div>
                                    <div class="col-span-1 flex justify-end">
                                        <a class="px-1">
                                            <Icon
                                                name="PencilAltIcon"
                                                color="primary-600"
                                                class="hover:text-primary-900"
                                            />
                                        </a>
                                        <a
                                            @click="isOpenRemove = true"
                                            class="px-1"
                                        >
                                            <Icon
                                                name="TrashIcon"
                                                color="red-600"
                                                class="hover:text-red-900"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <Text
                                size="sm"
                                color="primary-600"
                                content="+ Add"
                                decoration="underline"
                                custom-class="cursor-pointer mt-5"
                            />
                        </div>
                    </div>
                </div>
            </div> -->
            </div>
            <div
                class="mt-4 mx-0 sm:mx-8"
                v-if="selectedTab.name === 'Cohorts'"
            >
                <ListViewHeader
                    :title="`Cohorts (${cohorts?.length})`"
                    btn-text="Add"
                    :onClick="() => (isOpenAddCohort = true)"
                />
                <div class="space-y-3 mt-5">
                    <div v-if="cohorts && cohorts.length">
                        <CardWithBadge
                            v-for="cohort in json(getCohorts(cohorts))?.sort(
                                (a, b) =>
                                    a?.startDate > b?.startDate ? 1 : -11
                            )"
                            :key="cohort.id"
                            v-bind="cohort"
                            :onClick="
                                () => {
                                    $router.push({
                                        name: 'Ecosystem Program Cohort Detail',
                                        params: {
                                            id: cohort?.id,
                                            companyId: $route.params?.companyId,
                                            programId: program?.id,
                                        },
                                    });
                                }
                            "
                        />
                    </div>
                    <EmptyState
                        v-else
                        class=""
                        isFull
                        :description="'No records available!'"
                    />
                    <CardFooterWithPageButtons
                        class="px-0 bg-opacity-0"
                        :onChangePerPage="
                            (count) => perPageChanged(count, 'cohortPagination')
                        "
                        :totalRecords="cohorts?.length"
                        :perPage="cohortPagination.recordPerPage"
                        :currentPage="cohortPagination.recordActivePage"
                        :onChangePage="
                            (page) => pageChanged(page, 'cohortPagination')
                        "
                    />
                </div>
                <WithNoSwiper
                    v-bind="{
                        isShow: isOpenAddCohort,
                        isLoading: isLoading,
                        isSaving: isCohortSaving,
                        list: cohortForm,
                        modelKey: 'modelField',
                        title: `Let's add new cohort to your program`,
                        content: `You'll be able to invite people to join and track their progress`,
                    }"
                    :saveForm="saveForm"
                    @onClose="
                        () => {
                            isOpenAddCohort = false;
                        }
                    "
                />
            </div>
            <div
                class="mt-4 mx-0 sm:mx-4"
                v-if="selectedTab.name === 'Ventures'"
            >
                <EcosystemVentureTeams
                    class="-mt-4"
                    :programId="this?.program?.id"
                    isNotAdd
                    :ventures="ventures"
                />
            </div>
            <div class="mt-4 mx-0 sm:mx-8" v-if="selectedTab.name === 'People'">
                <ListViewHeader
                    :title="`People (${people?.length})`"
                    btn-text=""
                    @onClick="() => (isShow = true)"
                    isShowSearchBox
                    @onSearch="(key) => (keyword = key)"
                />
                <div class="space-y-3 mt-5">
                    <Table
                        :list="getPeople(people)"
                        v-if="people && people.length"
                        isBoldTeam
                        isClickable
                        @onClickItem="
                            (item) =>
                                $router.push({
                                    name: 'Ecosystem People Detail',
                                    params: {
                                        companyId: company?.id,
                                        id: item?.id,
                                    },
                                })
                        "
                        :sortFields="['Name', 'Email', 'Team', 'Cohort']"
                        @onSort="(item) => (sortPeople = item)"
                    />
                    <EmptyState
                        v-else
                        class=""
                        isFull
                        :description="'No records available!'"
                    />
                    <CardFooterWithPageButtons
                        class="px-0 bg-opacity-0"
                        :onChangePerPage="
                            (count) => perPageChanged(count, 'peoplePagination')
                        "
                        :totalRecords="getList(people)?.length"
                        :perPage="peoplePagination.recordPerPage"
                        :currentPage="peoplePagination.recordActivePage"
                        :onChangePage="
                            (page) => pageChanged(page, 'peoplePagination')
                        "
                    />
                </div>
            </div>
            <div
                class="mt-4 mx-0 sm:mx-8"
                v-if="selectedTab.name === 'Opportunities'"
            >
                <ListViewHeader
                    :title="`Opportunities (${opportunities?.length})`"
                />
                <div class="space-y-3 mt-5">
                    <Table
                        :list="getOpportunities(opportunities)"
                        v-if="opportunities && opportunities.length"
                        :sortFields="[
                            'Opportunity',
                            'Team',
                            'Responses',
                            'Magnitude',
                            'Market',
                        ]"
                        @onSort="(item) => (sortOpportunity = item)"
                    />
                    <EmptyState
                        v-else
                        class=""
                        isFull
                        :description="'No records available!'"
                    />

                    <CardFooterWithPageButtons
                        class="px-0 bg-opacity-0"
                        :onChangePerPage="
                            (count) =>
                                perPageChanged(count, 'opportunityPagination')
                        "
                        :totalRecords="opportunities?.length"
                        :perPage="opportunityPagination.recordPerPage"
                        :currentPage="opportunityPagination.recordActivePage"
                        :onChangePage="
                            (page) => pageChanged(page, 'opportunityPagination')
                        "
                    />
                </div>
            </div>
        </div>

        <InvitePeople
            v-bind="{
                ...c.invitePeople,
                isShow: isShow,
                isLoading,
                url: inviteLink,
            }"
            @onClose="() => (isShow = false)"
            @onSubmit="(item) => onSendInvite(item)"
        />
    </div>
</template>

<script>
import ListViewItem from "@/components/organisms/ListViewItem/ListViewItem";
import DetailViewHeader from "@/components/molecules/DetailViewHeader/DetailViewHeader";
import SubNavigationBar from "@/components/molecules/SubnavigationBar/SubnavigationBar.vue";
import { c } from "@/components/constants";
import {
    cohortDetailForm,
    programDetailForm,
} from "../../.storybook/sampleData";
import Button from "@/components/atoms/Button/Button";
import Input from "@/components/molecules/Inputs/Component/Component";
import Select from "@/components/molecules/Selects/Component/Component";
import Text from "@/components/atoms/Text/Text";
import Badge from "@/components/molecules/Badge/Basic/Basic";
import Icon from "@/components/atoms/Icons/Icons";
import Table from "@/components/organisms/Tables/Simple/Simple";
import EcosystemVentureTeams from "@/pages/EcosystemVentureTeams";
import EcosystemPeople from "@/components/templates/EcosystemPeople/EcosystemPeople";
import ListViewHeader from "../components/molecules/ListViewHeader/WithTitleAndButton/WithTitleAndButton";
import CardWithBadge from "../components/molecules/CardListItem/CardWithBadge/CardWithBadge";
import CardWithImage from "../components/molecules/CardListItem/CardWithImage/CardWithImage";
import { Settings } from "../../settings";
import WithNoSwiper from "@/components/organisms/Modals/WithNoSwiper/WithNoSwiper";
import InvitePeople from "@/components/organisms/Modals/InvitePeople/InvitePeople";
import EmptyState from "@/components/organisms/EmptyState/Simple/Simple";
import { _ } from "vue-underscore";
import moment from "moment";
import CardFooterWithPageButtons from "@/components/organisms/Paginations/CardFooterWithPageButtons/CardFooterWithPageButtons.vue";
import numeral from "numeral";
import { json } from "overmind";
import { upperFirst } from "lodash";
import Loader from "../components/atoms/Loader/Loader.vue";

export default {
    components: {
        EcosystemPeople,
        EcosystemVentureTeams,
        ListViewItem,
        DetailViewHeader,
        SubNavigationBar,
        Button,
        Input,
        Select,
        Text,
        Badge,
        Icon,
        Table,
        ListViewHeader,
        CardWithBadge,
        CardWithImage,
        WithNoSwiper,
        InvitePeople,
        EmptyState,
        CardFooterWithPageButtons,
        Loader,
    },
    data() {
        return {
            inviteLink: `https://${Settings.mainDomain}/#/register-user?eco=${this.$route.params?.companyId}&p=${this.$route.params?.programId}`,
            detailViewHeader: c.detailViewHeader,
            formGroup: programDetailForm,
            formData: {},
            opportunity: {
                title: "Opportunity Data",
                description:
                    "Now that you have real data back from potential customers, you can do an apples-to-apples comparison to figure out the most viable combination of target customer segments and the problems those people have.",
                items: [
                    {
                        Opportunity: "#20 Do their exercises",
                        "Target Customer": "Adults in physical",
                        Responses: "29",
                        Magnitude: 27,
                        Market: "10.1M",
                    },
                ],
            },
            selectedTab: null,
            contacts: [],
            cohorts: [],
            program: null,
            people: [],
            opportunities: [],
            isOpenAddCohort: false,
            cohortForm: [...cohortDetailForm].map((item) => {
                return {
                    ...item,
                    fields: item.fields.filter(
                        (field) => field.modelField !== "program"
                    ),
                };
            }),
            isCohortSaving: false,
            c,
            isShow: false,
            isLoading: false,
            ventures: [],
            opportunityPagination: {
                recordActivePage: 1,
                recordPerPage: 10,
            },
            cohortPagination: {
                recordActivePage: 1,
                recordPerPage: 10,
            },
            peoplePagination: {
                recordActivePage: 1,
                recordPerPage: 10,
            },
            json,
            company: null,
            keyword: null,
            sortPeople: null,
            sortOpportunity: null,
            isPageLoading: false,
        };
    },
    async created() {
        this.isPageLoading = true;
        if (this.state.currentCompany?.id === this.$route.params?.companyId) {
            this.company = this.state.currentCompany;
        } else {
            const companies = await this.actions.company.getCompanies({
                where: { id: this.$route.params?.companyId },
                getValues: true,
                query: "companyDefault",
            });
            this.company = companies[0];
            this.actions.setCurrentCompany(this.company);
        }
        this.selectedTab = this.getTabItems()[0];
        const items = await this.actions.program.getPrograms({
            where: { id: this.$route.params?.id },
            getValues: true,
        });
        console.log(items[0], "program");
        this.program = items?.[0];
        this.formData.name = this.program?.name;
        this.formData.type = { name: this.program?.type };
        this.formData.duration = this.program.duration;
        if (
            this.program &&
            this.program.admins &&
            this.program.admins.length > 0
        ) {
            this.contacts = this.program.admins.map((user) => {
                return {
                    type: "Email",
                    icon: "MailIcon",
                    value: user?.fullName || "",
                    id: user?.id,
                };
            });
        }

        const userSurveys = await this.actions.userSurvey.getUserSurveys({
            where: {
                isArchived_not: true,
            },
            getValues: true,
            all: true,
            fragments: `{id  opportunity {id isArchived} profile {id} survey {id targetCustomerUser {id isArchived}}}`,
        });
        this.program.cohorts?.map((c) => {
            let people = 0,
                opportunities = 0;
            this.ventures = [...this.ventures, ...c?.ventures];
            c?.users?.map((u) => {
                const ventures = [];
                c?.ventures
                    ?.filter((v) =>
                        v?.users?.find((user) => user?.id === u?.id)
                    )
                    ?.map((v) => ventures.push(v?.name));
            });
            c?.ventures?.map((v) => {
                v?.users?.map((u) => {
                    if (!this.people?.find((p) => p?.id === u?.id)) {
                        this.people?.push({
                            id: u?.id,
                            Name:
                                upperFirst(u?.firstName) +
                                " " +
                                upperFirst(u?.lastName),
                            Email: u?.email,
                            Team: upperFirst(v?.name),
                            Cohort: c?.name,
                        });
                    }
                });
                people += v?.users?.length || 0;
                opportunities +=
                    v?.opportunities?.filter(
                        (o) =>
                            !o?.isArchived &&
                            o?.surveyOpportunities?.find(
                                (s) => s?.responses?.length > 0
                            )
                    )?.length || 0;
                v?.opportunities
                    ?.filter((o) => !o?.isArchived)
                    ?.map((o) => {
                        let responses = userSurveys?.filter(
                            (u) => u?.opportunity?.id === o?.id
                        )?.length;
                        const oppIndex = [...this.opportunities].findIndex(
                            (item) => item?.id === o?.id
                        );
                        if (oppIndex === -1 && responses > 0) {
                            this.opportunities?.push({
                                id: o?.id,
                                Opportunity: o?.name
                                    ?.split(" ")
                                    ?.slice(1, o?.name?.split(" ")?.legnth)
                                    .join(" "),
                                Team: v?.name,
                                Responses: responses,
                                Magnitude: {
                                    out: o?.outOfMarketPercentage || 0,
                                    low: o?.lowMarketPercentage || 0,
                                    mid: o?.midMarketPercentage || 0,
                                    hi: o?.hiMarketPercentage || 0,
                                    magnitude: o?.magnitudeScore || 0,
                                },
                                Market: numeral(o?.adjustedMarket || 0).format(
                                    "0,0"
                                ),
                            });
                        }
                    });
            });
            this.cohorts?.push({
                title: c?.name,
                id: c?.id,
                actionLabel: `${moment(c?.startDate).format("M/D")} - ${moment(
                    c?.endDate
                ).format("M/D")}`,
                subtitles: [
                    {
                        icon: "LightBulbIcon",
                        name: "Ventures",
                        count: c?.ventures?.length,
                    },
                    {
                        icon: "UsersIcon",
                        name: "People",
                        count: people,
                    },
                    {
                        icon: "ExclamationIcon",
                        name: "Opportunities",
                        count: opportunities,
                    },
                ],
            });
        });
        if (this.state.group?.groups?.length === 0) {
            await this.actions.group.getGroups();
        }
        this.isPageLoading = false;
    },
    methods: {
        getList(items) {
            return items?.filter((i) => {
                const values = [];
                Object.keys(i)
                    ?.filter((k) => k !== "id")
                    ?.map((k) => values?.push(i[k]));
                if (!this.keyword) {
                    return true;
                } else if (
                    this.keyword &&
                    values?.find((v) =>
                        v?.toLowerCase()?.includes(this.keyword?.toLowerCase())
                    )
                ) {
                    return true;
                } else {
                    return false;
                }
            });
        },
        getCohorts(items) {
            return items.slice(
                (this.cohortPagination.recordActivePage - 1) *
                    this.cohortPagination.recordPerPage,
                this.cohortPagination.recordPerPage *
                    this.cohortPagination.recordActivePage
            );
        },
        getPeople(items) {
            const data = [...this.getList(items)];
            if (!this.sortPeople) {
                return data.slice(
                    (this.peoplePagination.recordActivePage - 1) *
                        this.peoplePagination.recordPerPage,
                    this.peoplePagination.recordPerPage *
                        this.peoplePagination.recordActivePage
                );
            } else {
                let list = [];
                if (
                    this.sortPeople?.name === "Magnitude" &&
                    typeof items?.[0]?.Magnitude === "object"
                ) {
                    list = data.sort((a, b) =>
                        a[this.sortPeople.name]?.magnitude >
                        b[this.sortPeople.name]?.magnitude
                            ? this.sortPeople?.sort
                                ? 1
                                : -1
                            : this.sortPeople?.sort
                            ? -1
                            : 1
                    );
                } else if (this.sortPeople?.name === "Market") {
                    list = data.sort((a, b) =>
                        parseInt(
                            a[this.sortPeople.name]?.replace(/[^0-9]/gi, "")
                        ) >
                        parseInt(
                            b[this.sortPeople.name]?.replace(/[^0-9]/gi, "")
                        )
                            ? this.sortPeople?.sort
                                ? 1
                                : -1
                            : this.sortPeople?.sort
                            ? -1
                            : 1
                    );
                } else {
                    list = data.sort((a, b) =>
                        a[this.sortPeople.name]?.toString()?.toLowerCase() >
                        b[this.sortPeople.name]?.toString()?.toLowerCase()
                            ? this.sortPeople?.sort
                                ? 1
                                : -1
                            : this.sortPeople?.sort
                            ? -1
                            : 1
                    );
                }

                return list.slice(
                    (this.peoplePagination.recordActivePage - 1) *
                        this.peoplePagination.recordPerPage,
                    this.peoplePagination.recordPerPage *
                        this.peoplePagination.recordActivePage
                );
            }
        },
        getOpportunities(items) {
            const data = [...this.getList(items)];
            if (!this.sortOpportunity) {
                return data.slice(
                    (this.opportunityPagination.recordActivePage - 1) *
                        this.opportunityPagination.recordPerPage,
                    this.opportunityPagination.recordPerPage *
                        this.opportunityPagination.recordActivePage
                );
            } else {
                let list = [];
                if (
                    this.sortOpportunity?.name === "Magnitude" &&
                    typeof items?.[0]?.Magnitude === "object"
                ) {
                    list = data.sort((a, b) =>
                        a[this.sortOpportunity.name]?.magnitude >
                        b[this.sortOpportunity.name]?.magnitude
                            ? this.sortOpportunity?.sort
                                ? 1
                                : -1
                            : this.sortOpportunity?.sort
                            ? -1
                            : 1
                    );
                } else if (this.sortOpportunity?.name === "Market") {
                    list = data.sort((a, b) =>
                        parseInt(
                            a[this.sortOpportunity.name]?.replace(
                                /[^0-9]/gi,
                                ""
                            )
                        ) >
                        parseInt(
                            b[this.sortOpportunity.name]?.replace(
                                /[^0-9]/gi,
                                ""
                            )
                        )
                            ? this.sortOpportunity?.sort
                                ? 1
                                : -1
                            : this.sortOpportunity?.sort
                            ? -1
                            : 1
                    );
                } else {
                    list = data.sort((a, b) =>
                        a[this.sortOpportunity.name]
                            ?.toString()
                            ?.toLowerCase() >
                        b[this.sortOpportunity.name]?.toString()?.toLowerCase()
                            ? this.sortOpportunity?.sort
                                ? 1
                                : -1
                            : this.sortOpportunity?.sort
                            ? -1
                            : 1
                    );
                }

                return list.slice(
                    (this.opportunityPagination.recordActivePage - 1) *
                        this.opportunityPagination.recordPerPage,
                    this.opportunityPagination.recordPerPage *
                        this.opportunityPagination.recordActivePage
                );
            }
        },
        async perPageChanged(count, key) {
            this[key].recordPerPage = count;
        },
        async pageChanged(page, key) {
            this[key].recordActivePage = page;
        },
        getTabItems() {
            return [
                {
                    name: "Details",
                    count: null,
                    href: "#",
                    icon: "PencilIcon",
                    current: false,
                },
                {
                    name: "Cohorts",
                    count: this.cohorts?.length || 0,
                    href: "#",
                    icon: "CalendarIcon",
                    current: false,
                },
                {
                    name: "Ventures",
                    count: this.ventures?.length || 0,
                    href: "#",
                    icon: "LightBulbIcon",
                    current: false,
                },
                {
                    name: "People",
                    count: this.people?.length || 0,
                    href: "#",
                    icon: "UsersIcon",
                    current: false,
                },
                {
                    name: "Opportunities",
                    count:
                        this.opportunities?.filter((o) => !o?.isArchived)
                            ?.length || 0,
                    href: "#",
                    icon: "ExclamationIcon",
                    current: false,
                },
            ];
        },
        async onClickMenu(item) {
            console.log(item, "item");
            if (item === "Archive") {
                await this.actions.program.deleteProgram({
                    id: this.$route.params?.programId,
                });
                this.actions.alert.showSuccess({
                    message: "Archived Successfully!",
                });
                this.$router.go(-1);
            }
        },
        async onSendInvite(item) {
            console.log(item, this.isLoading, "item =========");
            if (this.isLoading) return false;
            const emails = item[0]?.value;
            if (emails) {
                try {
                    this.isLoading = true;
                    const items = emails?.split(",");
                    const invites = [];
                    items.map((i) => {
                        invites.push({
                            email: i?.trim(),
                        });
                    });
                    await this.actions.user.inviteContact({
                        contacts: invites,
                        userId: this.state.currentUser?.id,
                        extraData: {
                            inviteLink: this.inviteLink,
                            ecosystemId: this.$route.params?.companyId,
                            ecosystemName:
                                this.state.currentUser?.companies?.find(
                                    (c) =>
                                        c?.id === this.$route.params?.companyId
                                )?.name,
                            originPath: `https://${Settings.mainDomain}/#/${this.$route.fullPath}`,
                            username: this.state.currentUser?.username,
                            fullName:
                                this.state.currentUser?.firstName +
                                " " +
                                this.state.currentUser?.lastName,
                        },
                    });
                    this.actions.alert.showSuccess({
                        message: "Sent invitations successfully!",
                    });
                    this.isShowPeople = false;
                } catch (e) {
                    console.log(e);
                } finally {
                    this.isLoading = false;
                }
            }
        },
        async onSubmitBtn(item) {
            if (item?.length > 0) {
                const { inviteContact } = await this.actions.user.inviteContact(
                    {
                        contacts: [{ email: item[0].value }],
                        userId: this.state.currentUser?.id,
                        extraData: {
                            ventureId: this.venture?.id,
                            ventureName: this.venture?.name,
                            groupId: item[1]?.value?.id,
                            username: this.state.currentUser?.username,
                            inviteLink: this.inviteLink,
                            fullName:
                                this.state.currentUser?.firstName +
                                " " +
                                this.state.currentUser?.lastName,
                        },
                    }
                );
                if (inviteContact) {
                    this.isShow = false;
                    setTimeout(
                        () =>
                            this.actions.alert.showSuccess({
                                message:
                                    "You've successfully sent your invitation",
                                title: "Invitation sent",
                            }),
                        1000
                    );
                }
            }
        },
        async saveForm(data) {
            console.log(data, "data");
            if (this.isCohortSaving) return false;
            this.isCohortSaving = true;
            const params = {
                cohorts: {
                    create: [
                        {
                            name: data?.name,
                            startDate: new Date(data?.startDate),
                            endDate: new Date(data?.endDate),
                            createdBy: {
                                connect: { id: this.state.currentUser?.id },
                            },
                            users: {
                                connect: [{ id: this.state.currentUser?.id }],
                            },
                        },
                    ],
                },
            };
            const response = await this.actions.saveFormData({
                mutation: "saveProgram",
                params,
                where: { id: this.program?.id },
            });
            if (response && response.id) {
                this.program = response;
                this.cohorts = [];
                this.program.cohorts?.map((c) => {
                    let people = 0,
                        opportunities = 0;
                    c?.ventures?.map((v) => {
                        people += v?.users?.length || 0;
                        v?.users?.map((u) => {
                            this.people?.push({
                                id: u?.id,
                                Name:
                                    upperFirst(u?.firstName) +
                                    " " +
                                    upperFirst(u?.lastName),
                                Email: u?.email,
                                Team: upperFirst(v?.name),
                                Cohort: c?.name,
                            });
                        });
                        opportunities +=
                            v?.opportunities?.filter((o) => !o?.isArchived)
                                ?.length || 0;
                        v?.opportunities
                            ?.filter((o) => !o?.isArchived)
                            ?.map((o) => {
                                let responses = 0;
                                o?.surveyOpportunities?.map(
                                    (so) =>
                                        (responses +=
                                            so?.responses?.length || 0)
                                );
                                const oppIndex = [
                                    ...this.opportunities,
                                ].findIndex((item) => item?.id === o?.id);
                                if (oppIndex === -1) {
                                    this.opportunities?.push({
                                        id: o?.id,
                                        Opportunity: o?.actionStep?.name,
                                        Team: v?.name,
                                        Responses: responses,
                                        Magnitude: o?.magnitudeScore,
                                        Market: o?.adjustedMarket,
                                    });
                                }
                            });
                    });
                    this.cohorts?.push({
                        title: c?.name,
                        id: c?.id,
                        actionLabel: "Activator",
                        subtitles: [
                            {
                                icon: "LightBulbIcon",
                                name: "Ventures",
                                count: c?.ventures?.length,
                            },
                            {
                                icon: "UsersIcon",
                                name: "People",
                                count: people,
                            },
                            {
                                icon: "ExclamationIcon",
                                name: "Opportunities",
                                count: opportunities,
                            },
                        ],
                    });
                });
                this.actions.alert.showSuccess({
                    message: "Program updated successfully",
                });
            }
            this.isCohortSaving = false;
            this.isOpenAddCohort = false;
        },
        async saveProgramForm() {
            if (this.isLoading) return false;
            this.isLoading = true;
            const params = {
                name: this.formData?.name,
                type: this.formData?.type?.name,
            };
            if (!_.isUndefined(this.formData?.duration))
                params["duration"] = parseFloat(this.formData?.duration);
            const response = await this.actions.saveFormData({
                mutation: "saveProgram",
                params,
                where: { id: this.program?.id },
            });
            if (response && response.id) {
                this.program = response;
                this.actions.alert.showSuccess({
                    message: "Program updated successfully",
                });
            }
            this.isLoading = false;
        },
        async saveCohortForm(data) {
            this.isCohortSaving = true;
            const params = {
                name: data.name,
                program: { connect: { id: this.program?.id } },
                createdBy: { connect: { id: this.state.currentUser?.id } },
            };
            if (data.startDate) params["startDate"] = data.startDate;
            if (data.endDate) params["endDate"] = data.endDate;
            const response = await this.actions.saveFormData({
                mutation: "saveCohort",
                params,
            });
            if (response && response.id) {
                this.cohorts?.push({
                    title: response?.name,
                    id: response?.id,
                    actionLabel: "Activator",
                    subtitles: [
                        {
                            icon: "LightBulbIcon",
                            name: "Ventures",
                            count: 0,
                        },
                        {
                            icon: "UsersIcon",
                            name: "People",
                            count: 0,
                        },
                        {
                            icon: "ExclamationIcon",
                            name: "Opportunities",
                            count: 0,
                        },
                    ],
                });
            }
            this.isCohortSaving = false;
            this.isOpenAddCohort = false;
        },
    },
};
</script>

<style scoped></style>
