export const surveyWithSteps = [
  {
    id: "Step 1",
    name: "Would you mind telling us a bit more about yourself?",
    status: "current",
    list: [
      {
        id: "5f8524162ab79c0007538761",
        label: "What is your age?",
        groupFields: true,
        fields: [
          {
            id: "5f8524162ab79c00075387161",
            inputType: "RADIO",
            isRequired: true,
            options: '["18-20", "25-39", "40-65", "65+", "Prefer not to say"]',
          },
        ],
        code: "ODR1",
      },
      {
        id: "5f8524162ab79c000753872",
        label: "How would you describe your gender?",
        code: "ODR1",
        groupFields: true,
        fields: [
          {
            id: "5f8524162ab79c0007538721",
            inputType: "RADIO",
            isRequired: true,
            options: '["Male", "Female", "Prefer not to say"]',
          },
        ],
      },
      {
        id: "5f8524162ab79c0007538763",
        label: "With which racial and ethnic group(s) do you identify?",
        code: "ODR1",
        // "required": true,
        groupFields: true,
        fields: [
          {
            id: "5f8524162ab79c00075387631",
            inputType: "RADIO",
            isRequired: true,
            options:
              '["Asian", "Black", "Latino(a)(x)", "Native American", "Native Hawaiian", "Mixed Race", "Prefer not to say"]',
          },
          {
            id: "5f8524162ab79c00075387632",
            inputType: "TEXT",
            isRequired: true,
          },
        ],
      },
      {
        id: "5f8524162ab79c0007324763",
        label: "Do you agree with this?",
        code: "OORRDD",
        // "required": true,
        groupFields: true,
        fields: [
          {
            id: "5f8524162ab79c00073247631",
            inputType: "RANGE_SLIDER",
            isRequired: true,
            min: -5,
            max: 5,
            marks: {
              "-5": {
                label: "Do not want to do at all",
              },
              "-4": {
                label: "",
              },
              "-3": {
                label: "",
              },
              "-2": {
                label: "would rather not",
              },
              "-1": {
                label: "",
              },
              0: {
                label: "Indifferent",
              },
              1: {
                label: "",
              },
              2: {
                label: "",
              },
              3: {
                label: "",
              },
              4: {
                label: "",
              },
              5: {
                label: "Desperately need to do",
              },
            },
          },
        ],
      },
      {
        id: "5f8524162ab79c0007538765",
        label: "What is your ZIP code?",
        inputType: "TEXT",
        code: "ODR1",
        fields: [
          {
            id: "5f8524162ab79c00075387651",
            inputType: "TEXT",
            isRequired: true,
          },
        ],
      },
      {
        id: "5f8524162ab79c0007538766",
        label: "I would like to talk more about this.", // not sure if this should be a label or label or in options
        code: "ODR1",
        fields: [
          {
            id: "5f8524162ab79c00075387661",
            inputType: "CHECKBOX_WITH_INPUT",
            isRequired: true,
            options:
              '["I would like to talk more about this.","Don\'t want to talk about it", "Neutral"]',
          },
        ],
      },
    ],
  },
  {
    id: "Step 2",
    name: "Would you mind telling us a bit more about yourself?",
    status: "current",
    list: [
      {
        id: "5f8524162ab79c00075387611",
        label: "What is your age?",
        code: "ODR1",
        required: false,
        groupFields: true,
        fields: [
          {
            id: "5f8524162ab79c000753876111",
            inputType: "RADIO",
            options: '["18-20", "25-39", "40-65", "65+", "Prefer not to say"]',
          },
        ],
      },
      {
        id: "5f8524162ab79c0007538722",
        label: "How would you describe your gender?",
        code: "ODR1",
        //"required": true,
        groupFields: true,
        fields: [
          {
            id: "5f8524162ab79c00075387221",
            inputType: "RADIO",
            options: '["Male", "Female", "Prefer not to say"]',
          },
        ],
      },
      {
        id: "5f8524162ab79c00075387633",
        label: "With which racial and ethnic group(s) do you identify?",
        code: "ODR1",
        //"required": true,
        groupFields: true,
        fields: [
          {
            id: "5f8524162ab79c000753876331",
            inputType: "RADIO",
            options:
              '["Asian", "Black", "Latino(a)(x)", "Native American", "Native Hawaiian", "Mixed Race", "Prefer not to say"]',
          },
        ],
      },
      {
        id: "5f8524162ab79c00075387654",
        label: "What is your ZIP code?",
        code: "ODR1",
        //"required": false,
        groupFields: true,
        fields: [
          {
            id: "5f8524162ab79c000753876541",
            inputType: "TEXT",
          },
        ],
      },
      {
        id: "5f8524162ab79c00075387665",
        label: "I would like to talk more about this.", // not sure if this should be a question or label or in options
        code: "ODR1",
        //"required": false,
        groupFields: true,
        fields: [
          {
            id: "5f8524162ab79c00075387665",
            inputType: "CHECKBOX",
            options: '["I would like to talk more about this."]',
          },
        ],
      },
    ],
  },
];

export const form = [
  {
    id: "1",
    name: "Idea Details",
    list: [
      {
        id: "11",
        cols: "2",
        fields: [
          {
            id: "111",
            modelField: "description",
            label:
              'Describe your idea by filling in blank, <br>"I\'m thinking about creating ..."',
            inputType: "TEXTAREA",
            isHtml: true,
            isRequired: true,
            placeholder:
              "i.e. a mobile app is make it easy to track physical therapy exercises at home",
            cols: "1",
          },
        ],
      },
      {
        id: "12",
        fields: [
          {
            id: "121",
            modelField: "name",
            label: "Give your idea a name for easy reference",
            inputType: "TEXT",
            isRequired: true,
            placeholder: "i.e. CoolApp OR Accountability Booster",
          },
        ],
      },
    ],
    modelForBox: {
      // still need to figure out
      content: "sample text with binded fields",
    },
  },
  {
    id: "2",
    name: "Target customer details",
    list: [
      {
        id: "21",
        fields: [
          {
            id: "211",
            modelField: "help",
            label: "Who needs your help?",
            inputType: "TEXT",
            isRequired: true,
            placeholder: "i.e. Adults in physical therapy OR millennial moms",
          },
        ],
      },
      {
        id: "22",
        fields: [
          {
            id: "221",
            modelField: "customerType",
            label:
              "Are they individual people, businesses, or something else ?",
            inputType: "DROPDOWN",
            placeholder: "Select type of target customer",
            options:
              '[{"id": 0, "name": "INDIVIDUALS"},{"id": 1, "name": "EMPLOYEES"},{"id": 2, "name": "BUSINESSES"},{"id": 3, "name": "ORGANIZATIONS"},{"id": 4, "name": "GOVERNMENTS"}]',
            helpText:
              "This will help determine how to research them and weather your potential business is B2B, B2C etc.",
            isRequired: true,
          },
        ],
      },
    ],
    modelForBox: {
      // still need to figure out
      content: "sample text with binded fields",
    },
  },
  {
    id: "21",
    name: "Organization Attributes",
    description:
      "Think about the bare minimum descriptors that will define who is in your Target Customer segment and who is not. ",
    list: [
      {
        id: "1",
        fields: [
          {
            id: "311",
            modelField: "revenue",
            label: "Annual Revenue or Budget (i.e. ONLY $0-$1M)",
            inputType: "TAGS",
            isObject: true,
            isNotAdd: false,
            options: "[]",
            placeholder: "Select one or multiple options",
          },
        ],
      },
      {
        id: "2",
        fields: [
          {
            id: "312",
            modelField: "employeeCount",
            label: "Employee Count (i.e. ONLY 1-10 employees)",
            inputType: "TAGS",
            isObject: true,
            isNotAdd: false,
            options: "[]",
            placeholder: "Select one or multiple options",
          },
        ],
      },
    ],
  },
  {
    id: "3",
    name: "Demographics, Interests, and Activities",
    description:
      "Think about the bare minimum descriptors that will define who is in your Target Customer segment and who is not. If you select more than one, you are saying BOTH can be in your Target Customer",
    list: [
      {
        id: "0",
        fields: [
          {
            id: "361",
            modelField: "interest",
            label: "Descriptors",
            addon: "They",
            inputType: "TAGS_WITH_ADDON",
            options: "[]",
            isObject: true,
            placeholder: "i.e. are in physical therapy OR have kids",
            helpText:
              'If an interest or activity isn\'t on the list, you can type it in and hit "enter" and it will be added as a new tag',
          },
        ],
      },
      {
        id: "1",
        fields: [
          {
            id: "311",
            modelField: "age",
            label: "Age (i.e. ONLY adults, or ONLY 55-64 years old)",
            inputType: "DROPDOWN",
            isObject: true,
            options: "[]",
            isNotAdd: true,
            limit: 1,
          },
        ],
      },
      {
        id: "2",
        fields: [
          {
            id: "321",
            modelField: "gender",
            label: "Gender (i.e. ONLY women or ONLY non-binary)",
            inputType: "DROPDOWN",
            limit: 1,
            isObject: true,
            options: "[]",
            isNotAdd: true,
            limit: 1,
          },
        ],
      },
      {
        id: "3",
        fields: [
          {
            id: "331",
            modelField: "ethnicity",
            label:
              "Race/Enthicity (i.e. ONLY of Hispanic, LatinX, or Spanish origin)",
            inputType: "DROPDOWN",
            isObject: true,
            options: "[]",
            isNotAdd: true,
            limit: 1,
          },
        ],
      },
      {
        id: "4",
        fields: [
          {
            id: "341",
            modelField: "income",
            label: "Income (i.e. ONLY low income)",
            inputType: "DROPDOWN",
            isObject: true,
            limit: 1,
            options: "[]",
            isNotAdd: true,
            limit: 1,
          },
        ],
      },
      {
        id: "5",
        fields: [
          {
            id: "351",
            modelField: "location",
            label: "Location",
            inputType: "LOCATION_TAGS",
            options: "[]",
            helpText:
              "This will help determine how to research them and weather your potential business is B2B, B2C etc.",
            isObject: true,
            isReqiured: true,
            limit: 1,
          },
        ],
      },
      {
        id: "6",
        fields: [
          {
            id: "311",
            modelField: "revenue",
            label: "Annual Revenue or Budget (i.e. ONLY $0-$1M)",
            inputType: "TAGS",
            isObject: true,
            isNotAdd: false,
            options: "[]",
            placeholder: "Select one or multiple options",
            limit: 1,
          },
        ],
      },
      {
        id: "7",
        fields: [
          {
            id: "312",
            modelField: "employeeCount",
            label: "Employee Count (i.e. ONLY 1-10 employees)",
            inputType: "TAGS",
            isObject: true,
            isNotAdd: false,
            options: "[]",
            placeholder: "Select one or multiple options",
            limit: 1,
          },
        ],
      },
    ],
    modelForBox: {
      // still need to figure out
      content: "sample text with binded fields",
    },
  },
  {
    id: "4",
    name: "Problem",
    description: "What do {targetCustomer.name} need your help with?",
    list: [
      {
        id: "41",
        fields: [
          {
            id: "411",
            modelField: "reasonFirst",
            addon: "They",
            inputType: "DROPDOWN_WITH_ADDON",
            placeholder: "i.e. need to, want to",
            options:
              '[{"id": 1, "name": "desperately need to", "value": "DESPERATELY_NEED_TO"},{"id": 2, "name": "have to", "value": "HAVE_TO"},{"id": 3, "name": "need to", "value": "NEED_TO"},{"id": 4, "name": "want to", "value": "WANT_TO"},{"id": 5, "name": "should", "value": "SHOULD"},{"id": 6, "name": "don\'t want to", "value": "DONT_WANT_TO"},{"id": 7, "name": "won\'t", "value": "WONT"}]',
          },
        ],
      },
      {
        id: "42",
        fields: [
          {
            id: "421",
            modelField: "reasonSecond",
            placeholder: "i.e. go to the grocery store OR manage their money",
            inputType: "AUTOCOMPLETE",
            options: "[]",
            isNotMark: true,
            isRequired: true,
          },
        ],
      },
      {
        id: "43",
        fields: [
          {
            id: "431",
            modelField: "reasonThird",
            addon: "but",
            inputType: "TAGS_WITH_ADDON",
            options: "[]",
            isObject: true,
            placeholder: "i.e. it takes too long, it cost too much",
            isNotAdd: true,
          },
        ],
      },
      {
        id: "44",
        fields: [
          {
            id: "441",
            addon: "because",
            modelField: "reasonFour",
            placeholder: "i.e. there aren't any stores near by",
            inputType: "TEXT_WITH_ADDON",
          },
        ],
      },
    ],
    modelForBox: {
      // still need to figure out
      content: "sample text with binded fields",
    },
  },
  {
    id: "5",
    name: "Existing Solution",
    description: "How do they currently {targetCustomerUser.researchPrompt}?",
    list: [
      {
        id: "51",
        fields: [
          {
            id: "511",
            inputType: "TABLE_WITH_INPUT",
            key: "label",
            options: "[]",
            modelField: "solutions",
            placeholder: "i.e. drive their car OR ride the bus",
          },
        ],
      },
    ],
    modelForBox: {
      // still need to figure out
      content: "sample text with binded fields",
    },
  },
];

export const contactForm = [
  {
    id: "1",
    fields: [
      {
        id: "11",
        modelField: "firstName",
        label: "First Name or Username *",
        inputType: "TEXT",
        placeholder: "Sally or sallyjones1234",
        isRequired: true,
      },
      {
        id: "12",
        modelField: "lastName",
        label: "Last Name",
        inputType: "TEXT",
        placeholder: "Jones",
      },
      {
        id: "13",
        modelField: "email",
        label: "Email Address",
        inputType: "TEXT",
        placeholder: "Email Address",
      },
      {
        id: "14",
        modelField: "socialLink",
        label: "Social Link",
        inputType: "TEXT",
        placeholder: "instagram.com/sallyjones12345",
      },
      {
        id: "15",
        modelField: "platform",
        label: "Platform",
        inputType: "DROPDOWN",
        options:
          '[{"id": 1, "name": "INSTAGRAM"},{"id": 2, "name": "FACEBOOK"},{"id": 3, "name": "TWITTER"},{"id": 4, "name": "TIKTOK"},{ "id": 6, "name": "LINKEDIN"},{ "id": 7, "name": "REDDIT"},{"id": 5, "name": "OTHER"}]',
      },
      {
        id: "16",
        modelField: "targetCustomer",
        label: "Target Customer *",
        inputType: "DROPDOWN",
        options: "[]",
        placeholder: "Select a Target Customer",
        isRequired: true,
      },
      {
        id: "17",
        modelField: "relationship",
        label: "Relationship *",
        inputType: "DROPDOWN",
        placeholder: "How well do you know this person?",
        options:
          '[{"id": 1, "value": "FRIEND_FAMILY", "name": "Friend Family"},{"id": 2, "value": "ACQUAINTANCE_INTRODUCTION", "name": "Acquaintance Introduction"}, {"id": 3, "value": "STRANGER", "name": "Stranger"}]',
        isRequired: true,
      },
    ],
  },
];

export const subContactForm = [
  {
    id: "1",
    fields: [
      {
        id: "15",
        modelField: "type",
        label: "Type",
        inputType: "DROPDOWN",
        options:
          '[{"id": 1, "name": "EMAIL"},{"id": 2, "name": "PHONE"},{"id": 3, "name": "SOCIAL"}]',
        isRequired: true,
      },
      {
        id: "12",
        modelField: "email",
        label: "Email",
        inputType: "TEXT",
        placeholder: "",
      },
      {
        id: "13",
        modelField: "phone",
        label: "Phone",
        inputType: "TEXT",
        placeholder: "",
        mask: "(###) ###-####",
      },
      {
        id: "11",
        modelField: "name",
        label: "Name",
        inputType: "TEXT",
        placeholder: "",
      },
      {
        id: "15",
        modelField: "platform",
        label: "Platform",
        inputType: "DROPDOWN",
        options:
          '[{"id": 1, "name": "INSTAGRAM"},{"id": 2, "name": "FACEBOOK"},{"id": 3, "name": "TWITTER"},{"id": 4, "name": "TIKTOK"},{ "id": 6, "name": "LINKEDIN"},{"id": 5, "name": "OTHER"}]',
      },
    ],
  },
];

export const targetCustomerForm = [
  {
    id: "1",
    fields: [
      {
        id: "11",
        modelField: "name",
        label: "Name *",
        inputType: "TEXT",
        placeholder: "i.e. Adults in physical therapy OR Millenial moms",
        isRequired: true,
      },
      {
        id: "12",
        modelField: "type",
        label: "Type *",
        inputType: "DROPDOWN",
        options:
          '[{"id": 0, "name": "INDIVIDUALS"},{"id": 1, "name": "EMPLOYEES"},{"id": 2, "name": "BUSINESSES"},{"id": 3, "name": "ORGANIZATIONS"},{"id": 4, "name": "GOVERNMENTS"}]',
        helpText:
          "This will help determine how to research them and whether your potential business is B2B, B2C, etc.",
        isRequired: true,
      },
      {
        id: "13",
        modelField: "header",
        label: "Research Prompt",
        content:
          "What are you hoping to learning about this Target Customer? Your research prompt will help generate content for your interviews and surveys.",
        inputType: "HEADER",
      },
      {
        id: "14",
        modelField: "socialLink",
        label:
          "We are learning about the real experiences of {name} and how they...",
        isHtml: true,
        inputType: "TEXT",
        placeholder: "i.e. keep track of their exercises at home",
        isRequired: true,
      },

      {
        id: "15",
        modelField: "header",
        label: "Descriptors, and Demographics",
        content:
          "Think about the bare minimum descriptors that will define who is in your Target Customer segment and who is not. If you select more than one, you are saying BOTH can be in your Target Customer",
        inputType: "HEADER",
      },
      {
        id: "21",
        modelField: "interest",
        label: "Descriptors",
        addon: "They",
        inputType: "TAGS_WITH_ADDON",
        options: "[]",
        isObject: true,
        placeholder: "i.e. are in physical therapy OR have kids",
        helpText:
          'If an interest or activity isn\'t on the list, you can type it in and hit "enter" and it will be added as a new tag',
      },
      {
        id: "16",
        modelField: "age",
        label: "Age (i.e. ONLY adults, or ONLY 55-64 years old)",
        inputType: "DROPDOWN",
        isObject: true,
        options: "[]",
        isNotAdd: true,
      },
      {
        id: "17",
        modelField: "gender",
        label: "Gender (i.e. ONLY women or ONLY non-binary)",
        inputType: "DROPDOWN",
        limit: 1,
        isObject: true,
        options: "[]",
        isNotAdd: true,
      },
      {
        id: "18",
        modelField: "ethnicity",
        label:
          "Race/Enthicity (i.e. ONLY of Hispanic, LatinX, or Spanish origin)",
        inputType: "DROPDOWN",
        isObject: true,
        options: "[]",
        isNotAdd: true,
      },
      {
        id: "19",
        modelField: "income",
        label: "Income (i.e. ONLY low income)",
        inputType: "DROPDOWN",
        isObject: true,
        limit: 1,
        options: "[]",
        isNotAdd: true,
      },
      {
        id: "20",
        modelField: "location",
        label: "Location",
        inputType: "LOCATION_TAGS",
        options: "[]",
        isObject: true,
      },
      {
        id: "22",
        modelField: "header",
        label: "Organization Attributes",
        content:
          "Think about the bare minimum descriptors that will define who is in your Target Customer segment and who is not.",
        inputType: "HEADER",
      },
      {
        id: "21",
        modelField: "interest",
        label: "Descriptors",
        addon: "They",
        inputType: "TAGS_WITH_ADDON",
        options: "[]",
        isObject: true,
        placeholder: "i.e. are in physical therapy OR have kids",
        helpText:
          'If an interest or activity isn\'t on the list, you can type it in and hit "enter" and it will be added as a new tag',
      },
      {
        id: "23",
        modelField: "revenue",
        label: "Annual Revenue or Budget (i.e. ONLY $0-$1M)",
        inputType: "TAGS",
        isObject: true,
        isNotAdd: true,
        options: "[]",
        placeholder: "Select one or multiple options",
      },
      {
        id: "24",
        modelField: "employeeCount",
        label: "Employee Count (i.e. ONLY 1-10 employees)",
        inputType: "TAGS",
        isObject: true,
        isNotAdd: true,
        options: "[]",
        placeholder: "Select one or multiple options",
      },

      {
        id: "15",
        modelField: "header",
        label: "Descriptors, and Demographics",
        content:
          "Think about the bare minimum descriptors that will define who is in your Target Customer segment and who is not. If you select more than one, you are saying BOTH can be in your Target Customer",
        inputType: "HEADER",
      },
      {
        id: "21",
        modelField: "interest",
        label: "Descriptors",
        addon: "They",
        inputType: "TAGS_WITH_ADDON",
        options: "[]",
        isObject: true,
        placeholder: "i.e. are in physical therapy OR have kids",
        helpText:
          'If an interest or activity isn\'t on the list, you can type it in and hit "enter" and it will be added as a new tag',
      },
      {
        id: "16",
        modelField: "age",
        label: "Age (i.e. ONLY adults, or ONLY 55-64 years old)",
        inputType: "TAGS",
        isObject: true,
        options: "[]",
        isNotAdd: true,
      },
      {
        id: "17",
        modelField: "gender",
        label: "Gender (i.e. ONLY women or ONLY non-binary)",
        inputType: "TAGS",
        limit: 1,
        isObject: true,
        options: "[]",
        isNotAdd: true,
      },
      {
        id: "18",
        modelField: "ethnicity",
        label:
          "Race/Enthicity (i.e. ONLY of Hispanic, LatinX, or Spanish origin)",
        inputType: "TAGS",
        isObject: true,
        options: "[]",
        isNotAdd: true,
      },
      {
        id: "19",
        modelField: "income",
        label: "Income (i.e. ONLY low income)",
        inputType: "TAGS",
        isObject: true,
        limit: 1,
        options: "[]",
        isNotAdd: true,
      },
      {
        id: "20",
        modelField: "location",
        label: "Location",
        inputType: "LOCATION_TAGS",
        options: "[]",
        isObject: true,
      },
      {
        id: "15",
        modelField: "header",
        label: "Employer Information (optional)",
        content: "",
        inputType: "HEADER",
      },
      {
        id: "23",
        modelField: "revenue",
        label: "Annual Revenue or Budget (i.e. ONLY $0-$1M)",
        inputType: "TAGS",
        isObject: true,
        isNotAdd: true,
        options: "[]",
        placeholder: "Select one or multiple options",
      },
      {
        id: "24",
        modelField: "employeeCount",
        label: "Employee Count (i.e. ONLY 1-10 employees)",
        inputType: "TAGS",
        isObject: true,
        isNotAdd: true,
        options: "[]",
        placeholder: "Select one or multiple options",
      },
    ],
  },
];
export const contactDetailForm = [
  {
    id: "1",
    fields: [
      {
        id: "11",
        modelField: "firstName",
        label: "First Name or Username",
        inputType: "TEXT",
        placeholder: "Sally or sallyjones1234",
        cols: 1,
      },
      {
        id: "12",
        modelField: "lastName",
        label: "Last Name",
        inputType: "TEXT",
        placeholder: "Jones",
        cols: 1,
      },
      {
        id: "13",
        modelField: "targetCustomer",
        label: "Target Customer",
        inputType: "DROPDOWN",
        options: "[]",
        placeholder: "",
        cols: 1,
      },
      {
        id: "14",
        modelField: "company",
        label: "Company",
        inputType: "AUTOCOMPLETE",
        options: "[]",
        placeholder: "Select a Company",
        cols: 1,
      },
      {
        id: "15",
        modelField: "relationship",
        label: "Relationship *",
        inputType: "DROPDOWN",
        placeholder: "",
        options:
          '[{"id": 1, "value": "FRIEND_FAMILY", "name": "Friend Family"},{"id": 2, "value": "ACQUAINTANCE_INTRODUCTION", "name": "Acquaintance Introduction"}, {"id": 3, "value": "STRANGER", "name": "Stranger"}]',
        cols: 1,
      },
      // {
      //   id: "16",
      //   modelField: "interviewDate",
      //   label: "Interview Date and Time",
      //   inputType: "DATETIME-LOCAL",
      //   placeholder: "Select a date and time",
      //   cols: 1,
      // },
      {
        id: "16",
        modelField: "info",
        label: "Interview Date and Time",
        cols: 2,
      },
      {
        id: "17",
        modelField: "notes",
        label: "Contact Notes",
        inputType: "TEXTAREA",
        cols: 2,
      },
    ],
  },
];
export const cohortDetailForm = [
  {
    id: "1",
    fields: [
      {
        id: "11",
        modelField: "name",
        label: "Name",
        inputType: "TEXT",
        cols: 1,
        placeholder: "i.e. IdeaMachine or Entrepreneurship 101",
        isRequired: true,
      },
      {
        id: "12",
        modelField: "program",
        label: "Program",
        inputType: "DROPDOWN",
        options: '[{"id": 1, "name": "TEST 1"},{"id": 2, "name": "TEST 2"}]',
        cols: 1,
      },
      {
        id: "13",
        modelField: "startDate",
        label: "Start Date",
        inputType: "DATE",
        cols: 1,
        placeholder: "Select a start date",
      },
      {
        id: "14",
        modelField: "endDate",
        label: "End Date",
        inputType: "DATE",
        cols: 1,
        placeholder: "Select an end date",
      },
    ],
  },
];

export const programDetailForm = [
  {
    id: "1",
    fields: [
      {
        id: "11",
        modelField: "name",
        label: "Name",
        inputType: "TEXT",
        cols: 1,
      },
      {
        id: "12",
        modelField: "duration",
        label: "Duration (weeks)",
        inputType: "NUMBER",
        cols: 1,
      },
      {
        id: "13",
        modelField: "type",
        label: "Type",
        inputType: "DROPDOWN",
        options:
          '[{"id": 1, "name": "ACCELERATOR"},{"id": 2, "name": "CLASS"}]',
        cols: 1,
      },
    ],
  },
];

export const opportunityDetailForm = [
  {
    id: "1",
    fields: [
      {
        id: "11",
        modelField: "targetCustomer",
        label: "Target Customer",
        inputType: "DROPDOWN",
        cols: 1,
        options: "[]",
        helpText: "Which of your target customers does this?",
        disabled: true,
      },
      {
        id: "12",
        modelField: "motivation",
        label: "Motivation",
        inputType: "DROPDOWN",
        cols: 1,
        helpText:
          "How do they feel about this? How strongly positive or negative?",
        options:
          '[{"id": 1, "name": "desperately need to", "value": "DESPERATELY_NEED_TO"},{"id": 2, "name": "have to", "value": "HAVE_TO"},{"id": 3, "name": "need to", "value": "NEED_TO"},{"id": 4, "name": "want to", "value": "WANT_TO"},{"id": 5, "name": "should", "value": "SHOULD"},{"id": 6, "name": "don\'t want to", "value": "DONT_WANT_TO"},{"id": 7, "name": "won\'t", "value": "WONT"}]',
      },
      {
        id: "13",
        modelField: "action",
        label: "Action",
        inputType: "AUTOCOMPLETE",
        cols: 2,
        options: "[]",
        helpText:
          "What do they do? You can write whatever you want or link to an existing action",
      },
      {
        id: "14",
        modelField: "type",
        label: "Type",
        inputType: "DROPDOWN",
        options:
          '[{"id": 1, "name": "ACTIVITY"},{"id": 2, "name": "MOTIVATOR"},{"id": 3, "name": "SOLUTION"}]',
        cols: 1,
        helpText:
          'Activities are things that people do with a known frequency, whereas Motivators might be more mental, like "managing their finances"',
      },
      {
        id: "15",
        modelField: "frequency",
        label: "Frequency",
        inputType: "NUMBER_ADDON",
        cols: 1,
        helpText:
          "If this is an Acvitity, how often do they do this? The more often they do something, the bigger the potential opportunity.",
      },
      {
        id: "16",
        modelField: "problems",
        label: "Problems",
        inputType: "TABLE",
        list: [],
        cols: 2,
        helpText:
          "How would someone describe their problems with doing this, if they can do it at all?",
      },
      {
        id: "17",
        modelField: "context",
        label: "Problem Context",
        inputType: "TEXT_WITH_ADDON",
        cols: 2,
        addon: "Because",
        helpText:
          "Why is this a problem? Adding context can help give clues as to how to solve the problem",
      },
    ],
  },
];

export const interviewForm = [
  {
    id: "1",
    fields: [
      {
        id: "10",
        modelField: "scheduledDateTime",
        label: "Interview Date and Time",
        inputType: "DATETIME-LOCAL",
        cols: 1,
      },
      {
        id: "11",
        modelField: "description",
        label: "Notes",
        inputType: "TEXTAREA",
        cols: 1,
      },
      {
        id: "12",
        modelField: "recordingUrl",
        label: "Recording URL",
        inputType: "TEXT",
        cols: 1,
      },
    ],
  },
];

export const opportunityForm = [
  {
    id: "1",
    fields: [
      {
        id: "10",
        modelField: "action",
        label: "Action",
        inputType: "HEADER",
      },
      {
        id: "11",
        modelField: "targetCustomer",
        label: "Target Customer *",
        inputType: "DROPDOWN",
        options: "[]",
        isRequired: true,
      },
      {
        id: "12",
        modelField: "motivation",
        label: "Motivation *",
        inputType: "DROPDOWN",
        options:
          '[{"id": 1, "name": "desperately need to", "value": "DESPERATELY_NEED_TO"},{"id": 2, "name": "have to", "value": "HAVE_TO"},{"id": 3, "name": "need to", "value": "NEED_TO"},{"id": 4, "name": "want to", "value": "WANT_TO"},{"id": 5, "name": "should", "value": "SHOULD"},{"id": 6, "name": "don\'t want to", "value": "DONT_WANT_TO"},{"id": 7, "name": "won\'t", "value": "WONT"}]',
        isRequired: true,
      },
      {
        id: "13",
        modelField: "action",
        label: "Action *",
        inputType: "AUTOCOMPLETE",
        placeholder: "i.e. go to the grocery store OR manage their money",
        options: "[]",
        isReqiured: true,
      },
      // {
      //   id: "14",
      //   modelField: "activity",
      //   label: "Activity or Motivator? *",
      //   inputType: "DROPDOWN",
      //   helpText:
      //     "Activities are things people do which some kind of frequency (i.e. once a year), whereas a Motivator might be why someone does an Activity and doesn't have a frequency (i.e. manage their money)",
      //   options:
      //     '[{"id": 1, "name": "ACTIVITY"},{"id": 2, "name": "MOTIVATOR"},{"id": 3, "name": "SOLUTION"}]',
      //   isReqiured: true,
      // },
      {
        id: "15",
        modelField: "problems",
        label: "Problems",
        inputType: "HEADER",
      },
      {
        id: "16",
        modelField: "problem",
        label: "Are there any problems doing this?",
        inputType: "DROPDOWN",
        options: "[]",
        // placeholder: "i.e. it takes too long, it cost too much",
      },
      {
        id: "17",
        modelField: "problemContext",
        label: "Why is this a problem?",
        inputType: "TEXT_WITH_ADDON",
        placeholder: "i.e. because there's always too much traffic",
        addon: "Because",
      },
    ],
  },
];

export const opportunity = [
  {
    id: "1",
    name: "Heal as quickly as possible",
    type: "opportunity",
    count: "42",
  },
  {
    id: "2",
    name: "Do exercises at home",
    type: "feature",
  },
  {
    id: "3",
    name: "Keep track of what exercises to do",
    type: "feature",
    count: "35",
  },
  {
    id: "4",
    name: "Go to physical therapy appointments",
    type: "feature",
  },
  {
    id: "5",
    name: "Remember when to do their exercises",
    type: "feature",
  },
  {
    id: "6",
    name: "Take a picture and keep it on their phone",
    type: "solution",
  },
  {
    id: "7",
    name: "Keep sheets in folder",
    type: "solution",
  },
  {
    id: "8",
    name: "Update physical therapist about their progress",
    type: "solution",
  },
  {
    id: "9",
    name: "inform through email or phone",
    type: "solution",
  },
  {
    id: "10",
    name: "hello world",
    type: "opportunity",
  },
  {
    id: "11",
    name: "hello hello",
    type: "feature",
  },
];
export const opportunityLinks = [
  {
    id: "link-1",
    parentId: "1",
    childId: "3",
    type: "custom",
    linkType: "solid",
    text: "styled custom edge label",
    data: {
      label: "85%",
      subLabel: "8 out of 10",
    },
  },
  {
    id: "link-2",
    parentId: "1",
    childId: "4",
    type: "custom",
    linkType: "solid",
  },
  {
    id: "link-3",
    parentId: "1",
    childId: "5",
    linkType: "solid",
    type: "custom",
  },
  {
    id: "link-4",
    parentId: "3",
    childId: "6",
    type: "custom",
    linkType: "solid",
  },
  {
    id: "link-5",
    parentId: "3",
    childId: "7",
    type: "custom",
    linkType: "solid",
    data: {
      label: "82%",
      subLabel: "8 out of 10",
    },
  },
  {
    id: "link-6",
    parentId: "3",
    childId: "8",
    linkType: "solid",
  },
  {
    id: "link-7",
    parentId: "8",
    childId: "9",
    linkType: "dotted",
  },
];

export const programForm = [
  {
    id: "1",
    title: "Let's add a new program to your ecosystem",
    content: "You'll be able to create cohorts and invite teams to join",
    fields: [
      {
        id: "11",
        modelField: "name",
        label: "Name",
        inputType: "TEXT",
        cols: 1,
        placeholder: "i.e. IdeaMachine or Entrepreneurship 101",
        isRequired: true,
      },
      {
        id: "12",
        modelField: "type",
        label: "Select a program type",
        inputType: "DROPDOWN",
        helpText: "",
        options:
          '[{"id": 1, "name": "ACCELERATOR"},{"id": 2, "name": "CLASS"}]',
        isRequired: true,
      },
      {
        id: "13",
        modelField: "duration",
        label: "Duration (weeks)",
        inputType: "TEXT",
        cols: 1,
        placeholder: "Enter the number of weeks",
      },
      // {
      //   id: "14",
      //   modelField: "admin",
      //   label: "Admins for this program",
      //   inputType: "AUTOCOMPLETE",
      //   helpText: "",
      //   options: "[]",
      // },
    ],
  },
];
