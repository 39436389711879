<template>
    <div class="flex flex-col w-full h-screen relative">
        <div class="flex flex-col w-full mt-6 mb-2 sm:px-4">
            <slot name="header">
                <Header
                    @onAdd="() => toggleSlideover(true)"
                    :title="'Opportunities'"
                    :btnLabel="listHeader.btnText"
                    :count="items.length"
                    :searchbox="listHeader.searchbox"
                    :filter="listHeader.filter"
                    :sort="listHeader.sort"
                    :onSearchTextChange="(val) => (searchText = val)"
                    :isShowSearchBox="listHeader.showSearchbox"
                    @onFilter="(item) => (filter = item)"
                    @onSort="(item) => (sort = item)"
                />
            </slot>
        </div>
        <Loader v-if="isLoading" :isLoading="isLoading" />
        <div v-if="!isLoading" class="flex flex-col w-full h-screen px-4 sm:px-8 space-y-2">
            <EmptyState
                v-if="items && items.length === 0"
                icon="ExclamationIcon"
                title="Looks like there aren't any opportunities here!"
                description="Opportunities are things that people do and might have problems doing."
                btn-text="Add Opportunity"
                isFull
                :action="() => toggleSlideover(true)"
            />
            <div
                class="flex flex-col border border-gray-300"
                v-for="item in getFilterAndSort(items)"
                :key="item"
            >
                <Items v-bind="item" @onClick="onClickItem(item)" />
            </div>
            <CardFooterWithPageButtons
                v-if="items && items.length !== 0"
                class="px-0 bg-opacity-0"
                :onChangePerPage="(count) => perPageChanged(count)"
                :totalRecords="items && items.length"
                :perPage="perPage"
                :currentPage="currentPage"
                :onChangePage="(page) => pageChanged(page)"
            />
        </div>
    </div>
</template>

<script>
import EmptyState from "../../organisms/EmptyState/Simple/Simple";
import Header from "../../molecules/ListViewHeader/WithFilter/ListViewHeader.vue";
import Items from "../../molecules/CardListItem/CardWithSurveyResult/CardWithSurveyResult";
import Icon from "../../atoms/Icons/Icons.vue";
import Text from "../../atoms/Text/Text.vue";
import Simple from "../../organisms/Tables/Simple/Simple.vue";
import CardFooterWithPageButtons from "../../organisms/Paginations/CardFooterWithPageButtons/CardFooterWithPageButtons.vue";
import { c } from "../../constants.js";
import Loader from "../../atoms/Loader/Loader.vue";

/**
 * - Use it to show a LinkedRecordList
 */
export default {
    components: {
        Header,
        Items,
        Text,
        Simple,
        Icon,
        CardFooterWithPageButtons,
        EmptyState,
        Loader,
    },
    props: {
        /**
         * Use it for list view items
         */
        items: {
            type: Array,
            default: () => [],
        },
        listHeader: {
            type: Object,
            default: () => {},
        },
        recordPerPage: {
            type: Number,
            default: 10,
        },
        /**
         * total records in table
         */
        pageTotalRecords: {
            type: Number,
            default: 0,
        },
        /**
         * current page of record table
         */
        recordActivePage: {
            type: Number,
            default: 1,
        },
        /**
         * action to get page data
         */
        getPageData: {
            type: Function,
            defaullt: () => {},
        },
        /**
         * loading status
         */
        isLoading: {
            type: Boolean,
            default: false,
        },
        onClickItem: {
            type: Function,
            default: () => {},
        },
        toggleSlideover: {
            type: Function,
            default: () => {},
        },
        viewName: {
            type: String,
            default: "",
        },
        emptyViewBinding: {
            type: Object,
            default: () => {},
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            c,
            filter: null,
            sort: null,
            searchText: null,
            perPage: 10,
            currentPage: 1,
        };
    },
    methods: {
        getFilterAndSort(data) {
            try {
                let items = [...data];
                if (this.searchText) {
                    items = items.filter((item) =>
                        item?.detail?.name
                            .toLowerCase()
                            .includes(this.searchText?.trim().toLowerCase())
                    );
                }
                if (this.filter?.parent?.name === "Type") {
                    if (this.filter?.child?.name) {
                        items = items?.filter(
                            (t) =>
                                t?.detail?.type ===
                                this.filter?.child?.name?.toUpperCase()
                        );
                    } else {
                        items = items?.filter((t) => t?.detail?.type);
                    }
                }
                if (this.sort?.parent?.name === "Name") {
                    items = items.sort((a, b) => {
                        if (this.sort?.child?.id === 0) {
                            return a?.detail?.name > b?.detail?.name ? 1 : -1;
                        } else {
                            return a?.detail?.name > b?.detail?.name ? -1 : 1;
                        }
                    });
                } else if (this.sort?.parent?.name === "Linked Opportunities") {
                    items = items.sort((a, b) => {
                        if (this.sort?.child?.id === 0) {
                            return a?.detail?.parentOpportunityLinks?.length +
                                a?.detail?.childOpportunityLinks?.length >
                                b?.detail?.parentOpportunityLinks?.length +
                                    b?.detail?.childOpportunityLinks?.length
                                ? 1
                                : -1;
                        } else {
                            return a?.detail?.parentOpportunityLinks?.length +
                                a?.detail?.childOpportunityLinks?.length >
                                b?.detail?.parentOpportunityLinks?.length +
                                    b?.detail?.childOpportunityLinks?.length
                                ? -1
                                : 1;
                        }
                    });
                } else if (this.sort?.parent?.name === "Interviews") {
                    items = items.sort((a, b) => {
                        if (this.sort?.child?.id === 0) {
                            return a?.detail?.interviews?.filter(
                                (o) => !o?.isArchived
                            )?.length >
                                b?.detail?.interviews?.filter(
                                    (o) => !o?.isArchived
                                )?.length
                                ? 1
                                : -1;
                        } else {
                            return a?.detail?.interviews?.filter(
                                (o) => !o?.isArchived
                            )?.length >
                                b?.detail?.interviews?.filter(
                                    (o) => !o?.isArchived
                                )?.length
                                ? -1
                                : 1;
                        }
                    });
                } else if (this.sort?.parent?.name === "Survey Responses") {
                    items = items.sort((a, b) => {
                        if (this.sort?.child?.id === 0) {
                            return a?.detail?.surveys?.length >
                                b?.detail?.surveys?.length
                                ? 1
                                : -1;
                        } else {
                            return a?.detail?.surveys?.length >
                                b?.detail?.surveys?.length
                                ? -1
                                : 1;
                        }
                    });
                } else if (this.sort?.parent?.name === "Magnitude") {
                    items = items.sort((a, b) => {
                        if (this.sort?.child?.id === 0) {
                            return a?.detail?.magnitudeScore >
                                b?.detail?.magnitudeScore
                                ? 1
                                : -1;
                        } else {
                            return a?.detail?.magnitudeScore >
                                b?.detail?.magnitudeScore
                                ? -1
                                : 1;
                        }
                    });
                } else if (this.sort?.parent?.name === "Created Date") {
                    items = items.sort((a, b) => {
                        if (this.sort?.child?.id === 0) {
                            return a?.detail?.createdAt > b?.detail?.createdAt
                                ? 1
                                : -1;
                        } else {
                            return a?.detail?.createdAt > b?.detail?.createdAt
                                ? -1
                                : 1;
                        }
                    });
                }
                return items.slice(
                    (this.currentPage - 1) * this.perPage,
                    this.perPage * this.currentPage
                );
            } catch (e) {
                console.log(e);
            }
        },
        async perPageChanged(count) {
            this.perPage = count;
        },
        async pageChanged(page) {
            this.currentPage = page;
        },
        slideOver() {
            this.toggleSlideover(true);
        },
    },
};
</script>
