<template>
    <Template
        :content="{
            ...c.ecosystem_settings,
            ecosystems: this.state.currentUser?.transactions || [],
            contacts:
                [
                    { ...company?.owner, userType: 'Owner' },
                    ...company?.users?.filter((u) =>
                        u?.userGroups?.find(
                            (ug) => ug?.group?.name === 'ecosystem-admin-user'
                        )
                    ),
                ] || [],
            info: {
                name: {
                    label: 'Name',
                    name: 'name',
                    type: 'text',
                    value: formData?.name,
                },
                type: {
                    label: 'Organization Type',
                    name: 'type',
                    type: 'select',
                    value: formData?.type || null,
                    items: companyType,
                },
                website: {
                    label: 'Website',
                    name: 'url',
                    type: 'text',
                    value: formData?.url || '',
                },
            },
            logo: formData?.logo,
            payments: payments || [],
        }"
        @onChangeLogo="(file) => (formData.logoFile = file)"
        :isSavingEcosystem="isSavingEcosystem"
        :onSaveEcosystem="(data) => saveEcosystem(data)"
        :onRemoveEcosytemUser="
            (user) => {
                (isOpenRemove = true), (selectedEcosystemUser = user);
            }
        "
        :onInvitePeople="() => (isOpenInvite = true)"
        :isSavingPaymentMethod="isSavingPaymentMethod"
        :onSavePaymentMethod="(data) => savePaymentMethod(data)"
        :isPageLoading="isPageLoading"
        @onClickItem="onClickItem"
        @onDeletePayment="onDeletePayment"
    />
    <DeleteModal
        :is-show="isOpenRemove"
        @onClose="
            () => {
                (isOpenRemove = false), (selectedEcosystemUser = null);
            }
        "
        :title="`Remove this <b>Ecosytem User</b>?`"
        :description="`Are you sure you want to remove the user <b>${
            (selectedEcosystemUser?.firstName || '') +
            ' ' +
            (selectedEcosystemUser?.lastName || '')
        }</b> from your ecosystem <b>${content?.info?.name?.value || ''}</b>?`"
        yes-btn-text="Remove"
        no-btn-text="Cancel"
        icon="TrashIcon"
        yesBtnVariant="danger"
        noBtnVariant="secondary"
        :on-no-click="
            () => {
                (isOpenRemove = false), (selectedEcosystemUser = null);
            }
        "
        :on-yes-click="() => removeUserFromEcosystem(selectedEcosystemUser)"
    />
    <InvitePeople
        v-bind="{
            ...c.inviteUsers,
            isShow: isOpenInvite,
            isLoading,
            url: inviteLink,
        }"
        @onClose="() => (isOpenInvite = false)"
        @onSubmit="(item) => onSendInvite(item)"
    />
</template>

<script>
import Template from "../components/templates/EcosystemSettings/EcosystemSettings";
import DeleteModal from "../components//organisms/Modals/SimpleAlert/SimpleAlert";
import InvitePeople from "../components/organisms/Modals/InvitePeople/InvitePeople";
import { c } from "../components/constants.js";
import { Settings } from "../../settings";
import { getAWSCredential } from "@/utils/utils";
import AWS from "aws-sdk";
import { json } from "overmind";

export default {
    components: {
        Template,
        DeleteModal,
        InvitePeople,
    },
    data() {
        return {
            c,
            company: null,
            isOpenRemove: false,
            selectedEcosystemUser: null,
            isOpenInvite: false,
            isSavingEcosystem: false,
            companyType: [
                { name: "PUBLIC", id: 0 },
                { name: "PRIVATE", id: 1 },
                { name: "NONPROFIT", id: 2 },
                { name: "GOVERNMENT", id: 3 },
                { name: "EDUCATION", id: 4 },
                { name: "PERSONAL", id: 5 },
            ],
            formData: {},
            isSavingPaymentMethod: false,
            inviteLink: `https://${Settings.mainDomain}/#/register-user?eco=${this.$route.params?.companyId}`,
            isLoading: false,
            payments: [],
            logo: null,
            isPageLoading: false,
        };
    },
    async created() {
        this.isPageLoading = true;
        if (this.state.currentCompany?.id === this.$route.params?.companyId) {
            this.company = this.state.currentCompany;
        } else {
            const companies = await this.actions.company.getCompanies({
                where: { id: this.$route.params?.companyId },
                getValues: true,
                query: "companyDefault",
            });
            console.log(companies);
            this.company = companies[0];
            this.actions.setCurrentCompany(this.company);
        }
        console.log(this.company, "company");
        this.payments = json(this.company?.owner?.paymentMethods);
        this.formData.name = this?.company?.name || "";
        this.formData.type =
            this.companyType.find(
                (type) => type.name === this?.company?.type
            ) || null;
        this.formData.url = this?.company?.url;
        this.formData.logo = this?.company?.avatar;
        this.isPageLoading = false;
    },
    methods: {
        async onDeletePayment(payment) {
            this.isSavingPaymentMethod = true;
            const user = await this.actions.user.updateUserProfile({
                userId: this.company?.owner?.id,
                removePaymentMethods: [payment.id],
            });
            this.payments = json(user?.paymentMethods);
            this.isSavingPaymentMethod = false;
        },
        async onSendInvite(item) {
            if (this.isLoading) return false;
            const emails = item[0]?.value;
            if (emails) {
                try {
                    this.isLoading = true;
                    const items = emails?.split(",");
                    const invites = [];
                    items.map((i) => {
                        invites.push({
                            email: i?.trim(),
                        });
                    });
                    await this.actions.user.inviteContact({
                        contacts: invites,
                        userId: this.state.currentUser?.id,
                        extraData: {
                            inviteLink: this.inviteLink,
                            ecosystemId: this.$route.params?.companyId,
                            ecosystemName: this.company?.name,
                            originPath: `https://${Settings.mainDomain}/#/${this.$route.fullPath}`,
                            isAdmin: true,
                            username: this.state.currentUser?.username,
                            fullName:
                                this.state.currentUser?.firstName +
                                " " +
                                this.state.currentUser?.lastName,
                        },
                    });
                    this.actions.alert.showSuccess({
                        message: "Sent invitations successfully!",
                    });
                    this.isOpenInvite = false;
                } catch (e) {
                    console.log(e);
                } finally {
                    this.isLoading = false;
                }
            }
        },
        onClickItem(item) {
            this.actions.logout();
            this.$router.push("/login");
        },
        onUploadAvatar: async function (file) {
            let filename = file.name.split(" ").join("-");
            const paths = filename.split(".");
            if (paths.length > 0)
                paths[paths.length - 2] += `.${new Date().getTime()}`;
            filename = paths?.join(".");
            const { s3 } = await getAWSCredential(this.actions);
            const s3Bucket = new AWS.S3({
                params: { Bucket: s3.bucket },
                apiVersion: "2006-03-01",
                region: s3.region,
                credentials: {
                    accessKeyId: s3.Credentials.AccessKeyId,
                    secretAccessKey: s3.Credentials.SecretAccessKey,
                    sessionToken: s3.Credentials.SessionToken,
                },
            });

            const uploadImageToS3Promise = new Promise((resolve, reject) => {
                let contentType = file.type;
                let contentDeposition = 'inline;filename="' + filename + '"';
                const params = {
                    Bucket: s3.bucket,
                    Key: filename,
                    Body: file,
                    ContentDisposition: contentDeposition,
                    ContentType: contentType,
                    ACL: "public-read",
                };
                s3Bucket.upload(params, (err, res) => {
                    if (err) {
                        console.log("error in callback");
                        reject();
                        return false;
                    }
                    console.log("Response URL : " + res.Location);
                    this.logo = res.Location;
                    resolve();
                });
            });
            await uploadImageToS3Promise.then();
        },
        async saveEcosystem(data) {
            console.log(data, "data");
            this.formData = {
                ...this.formData,
                name: data?.name?.value || "",
                type: data?.type?.value,
                url: data?.website?.value,
            };
            let avatar = null;
            if (this.formData?.logoFile) {
                await this.onUploadAvatar(this.formData?.logoFile);
                avatar = this.logo;
            }

            this.isSavingEcosystem = true;
            const params = {
                data: {
                    name: data?.name?.value,
                    type: data?.type?.value?.name,
                    url: data?.website?.value,
                },
                where: { id: this?.company?.id },
            };
            if (avatar) params.data.avatar = avatar;
            console.log(params, "params");
            let { saveCompany } = await this.actions.company.saveCompany(
                params
            );
            const companies = await this.actions.company.getCompanies({
                where: { id: saveCompany?.id },
                getValues: true,
                query: "companyDefault",
            });
            saveCompany = companies?.[0];
            if (saveCompany && saveCompany?.id) {
                this.actions.alert.showSuccess({
                    message: "Ecosystem saved successfully!",
                });
                this.company = saveCompany;
                this.actions.setCurrentCompany(saveCompany);
            }
            this.isSavingEcosystem = false;
        },
        async removeUserFromEcosystem(user) {
            this.isOpenRemove = false;
            console.log(user, "removeUserFromEcosystem");
            const { saveCompany } = await this.actions.company.saveCompany({
                where: { id: this.company?.id },
                data: {
                    users: {
                        disconnect: [{ id: user?.id }],
                    },
                },
            });
            const companies = await this.actions.company.getCompanies({
                where: { id: saveCompany?.id },
                getValues: true,
                query: "companyDefault",
            });
            saveCompany = companies?.[0];
            this.company = saveCompany;
        },
        async savePaymentMethod(data) {
            console.log(data, "data");
            this.isSavingPaymentMethod = true;
            try {
                const expiration = data?.expiration?.split("/");
                const month = expiration?.[0] || "";
                const year = expiration?.[1] || "";
                const names = data?.name?.split(" ");
                const params = {
                    userId: this.company?.owner?.id,
                    paymentMethod: {
                        firstName: names[0],
                        lastName: names[1],
                        verificationCode: data?.cvv,
                        month: month,
                        year: year,
                        number: data?.number?.replace(/[^A-Z0-9]/gi, ""),
                        methodType: "credit_card",
                        type: "payment",
                        billingZip: data?.zipcode,
                    },
                };
                const user = await this.actions.user.updateUserProfile(params);
                console.log(user, "user");
                if (user?.id) {
                    await this.actions.user.updateUserProfile({
                        userId: this.company?.owner?.id,
                        setDefaultPaymentMethodId:
                            user?.paymentMethods[
                                user?.paymentMethods?.length - 1
                            ]?.id,
                    });
                    this.payments = json(user?.paymentMethods);
                    this.actions.alert.showSuccess({
                        message: "Payment method saved successfully!",
                    });
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.isSavingPaymentMethod = false;
            }
        },
    },
};
</script>

<style scoped></style>
