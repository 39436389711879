
<template>
  <Listbox v-model="selectedValue" as="div">
    <ListboxLabel v-if="label" class="block text-sm font-semibold text-gray-700">
      {{ label }}
    </ListboxLabel>
    <div class="relative mt-1 flex flex-col flex-1">
      <ListboxButton
        class="relative w-full h-10 flex text-left bg-white rounded-md  cursor-default sm:text-sm"
        :class="disabled ? 'bg-transparent border-0' : 'border border-gray-300 shadow-sm focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500'"
        >
        <div v-if="addon" class="px-3 flex justify-center items-center bg-gray-100 border-r border-gray-300 rounded-l-md"
          style="min-height: 38px;">
          <Text size="sm" color="black" :content="addon" />
        </div>
        <span v-if="selectedValue" :class="disabled ? 'pl-0' : `pl-3`" class="flex items-center pr-10 py-2" >
          <Avatar v-if="selectedValue && selectedValue.avatar" :src="selectedValue && selectedValue.avatar" is-circle
            size="6" class="flex-shrink-0" />
          <span v-else-if="selectedValue && selectedValue.online !== undefined"
            :aria-label="selectedValue && selectedValue.online ? 'Online' : 'Offline'"
            :class="[selectedValue.online ? 'bg-green-400' : 'bg-gray-200', 'flex-shrink-0 inline-block h-2 w-2 rounded-full']" />
          <span class="inline-flex w-full truncate space-x-3">
            <Text :content="selectedValue ? selectedValue.name : selectedItem && selectedItem.name"
              custom-class="truncate sm:text-sm" :customClass="disabled ? 'font-bold': ''" />
            <Text v-if="selectedValue && selectedValue.username" :content="selectedValue && selectedValue.username"
              color="gray-500" custom-class="ml-2 truncate" />
            <Icon v-if="selectedValue && selectedValue.isSent && isSent" name="CheckIcon" size="5"
              :color="`${active ? 'white' : 'primary-600'}`" customClass="absolute right-10" />
          </span>
        </span>
        <span class="text-gray-400 pl-3 pr-10 py-2" v-else>{{ placeholder }}</span>
      </ListboxButton>
      <span class="absolute inset-y-0 right-0 pr-2 ml-3 flex items-center space-x-1 z-10">
        <Icon v-if="isClear && selectedValue" name="XIcon" color="gray-400" size="5"
          custom-class="cursor-pointer hover:text-gray-700" @click.stop="() => $emit('update:selectedItem', null)" />
        <Icon class="selector-icon" name="SelectorIcon" color="gray-400" size="5" v-if="!disabled" />
      </span>

      <transition v-if="!disabled" leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
        leave-to-class="opacity-0">
        <ListboxOptions as="div"
          :class="`absolute ${customClass} z-50 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`">
          <ListboxOption v-for="(item, key) in items" :key="key" :disabled="typeof item.id === 'undefined'"
            v-slot="{ active, selected }" as="template" :value="item">
            <div @click="() => { if (typeof item.id !== 'undefined') selectedItem = item }" :class="[
              active ? 'text-white bg-primary-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9',
              typeof item.id === 'undefined' ? 'bg-gray-200' : '']">
              <div class="flex items-center">
                <Avatar v-if="item && item.avatar" :src="item && item.avatar" is-circle size="6" class="flex-shrink-0" />
                <span v-else-if="item && item.online !== undefined"
                  :class="[item.online ? 'bg-green-400' : 'bg-gray-200', 'flex-shrink-0 inline-block h-2 w-2 rounded-full']"
                  aria-hidden="true" />

                <div class="flex">
                  <Text :content="item && item.name"
                    :weight="`${typeof item.id === 'undefined' ? 'bold' : selected ? 'semibold' : 'normal'}`"
                    :color="`${active ? 'white' : 'gray-900'}`" custom-class="block ml-3 truncate" />
                  <Text v-if="item && item.username" :content="item && item.username"
                    :color="`${active ? 'primary-200' : 'gray-500'}`" custom-class="ml-2 truncate " />
                </div>
              </div>

              <span v-if="isSent ? item.isSent : selected" class="absolute inset-y-0 right-0 flex items-center pr-4">
                <Icon name="CheckIcon" size="5" :color="`${active ? 'white' : 'primary-600'}`" />
              </span>
            </div>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
    <Text size="xs" :content="helpText" weight="normal" color="gray-400" custom-class="mt-1 italic" v-if="helpText" />
  </Listbox>
</template>

<script>
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import Icon from "../../../atoms/Icons/Icons.vue";
import Text from "../../../atoms/Text/Text.vue";
import Avatar from "../../../atoms/Avatar/Avatar.vue";

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    Icon,
    Text,
    Avatar,
  },
  props: {
    helpText: {
      type: String,
      default: ''
    },
    customClass: {
      type: String,
      defalut: ""
    },
    /**
     * items to show in select
     */
    items: {
      type: Array,
      default: () => [],
    },
    /**
     * used to set predefined value.
     */
    selectedItem: {
      type: Object,
      default: () => { },
    },
    placeholder: {
      type: String,
      defalut: ""
    },
    /**
     * label of select
     */
    label: {
      type: String,
      default: "",
    },
    isSent: {
      type: Boolean,
      default: false,
    },
    isClear: {
      type: Boolean,
      default: false,
    },
    addon: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      default: () => {}
    }
  },

  computed: {
    selectedValue: {
      get() {
        return this.selectedItem;
      },
      set(newValue) {
        this.$emit("update:selectedItem", newValue);
        this.onChange(newValue);
      },
    },
  }
};
</script>
