<template>
    <div class="flex flex-col w-full h-screen">
        <div class="flex flex-col w-full mt-6 mb-2 sm:px-4">
            <Header
                @onAdd="() => $emit('onAdd')"
                :title="'Interviews'"
                btnLabel="Add Interview"
                isNotAdd
                :count="pagination && pagination.totalRecords"
                :searchbox="{ placeholder: 'Search', icon: 'SearchIcon' }"
            />
            <!--			<div class="flex justify-between items-center px-4">-->
            <!--				<Text size="sm" color="primary-600" content="View Interview Schedule" decoration="underline" custom-class="cursor-pointer"/>-->
            <!--				<div class="flex items-center space-x-3">-->
            <!--					<Text size="sm" color="primary-600" content="Import Interviews" decoration="underline" custom-class="cursor-pointer"/>-->
            <!--					<Text size="sm" color="primary-600" content="Export as CSV" decoration="underline" custom-class="cursor-pointer"/>-->
            <!--				</div>-->
            <!--			</div>-->
        </div>
        <div class="flex flex-col w-full h-screen px-4 sm:px-8 space-y-2">
            <EmptyState
                v-if="contact && contacts.length === 0"
                class="bg-white"
                isFull
                v-bind="emptyInterview"
                :action="
                    () => {
                        $emit('onAdd');
                    }
                "
            />
            <Table
                v-if="contacts?.length > 0"
                :list="getInterviews(contacts)"
                :headers="headers"
                isClickable
                @onClickItem="(item) => $emit('onClickItem', item)"
                isRow
            />
            <CardFooterWithPageButtons
                v-if="contacts && contacts.length !== 0"
                class="px-0 bg-opacity-0"
                :onChangePerPage="(count) => perPageChanged(count)"
                :totalRecords="contacts && contacts.length"
                :perPage="perPage"
                :currentPage="currentPage"
                :onChangePage="(page) => pageChanged(page)"
            />
        </div>
    </div>
</template>

<script>
import Header from "../../molecules/ListViewHeader/WithFilter/ListViewHeader.vue";
import Text from "../../atoms/Text/Text";
import Table from "../../organisms/Tables/WithSortAndFilter/WithSortAndFilter.vue";
import CardFooterWithPageButtons from "../../organisms/Paginations/CardFooterWithPageButtons/CardFooterWithPageButtons.vue";
import EmptyState from "../../organisms/EmptyState/Simple/Simple.vue";

export default {
    components: {
        Header,
        Text,
        Table,
        CardFooterWithPageButtons,
        EmptyState,
    },
    props: {
        contacts: {
            type: Array,
            default: () => [],
        },
        pagination: {
            type: Object,
            default: () => {},
        },
        headers: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            emptyInterview: {
                title: "Let's add the interviews",
                description: "Please add the interviews",
                btnText: "Add Interview",
                icon: "LightBulbIcon",
            },
            perPage: 10,
            currentPage: 1,
        };
    },
    methods: {
        getInterviews(items) {
            return items.slice(
                (this.currentPage - 1) * this.perPage,
                this.perPage * this.currentPage
            );
        },
        async perPageChanged(count) {
            this.perPage = count;
        },
        async pageChanged(page) {
            this.currentPage = page;
        },
    },
};
</script>

<style scoped></style>
